import { FunctionComponent, useEffect, useState, useMemo } from 'react';

import { Stack, Typography, Card, CardContent } from '@mui/material';
import { CropLandscape, TipsAndUpdates } from '@mui/icons-material';

import { IMenuItem, IObjectCountMetric, TChartLegend, TColor, TChartType } from '@luxon/interfaces';
import { Chart, Select, MetricsRange, Button, PageFrameSkeletonLoader } from '@luxon/components';
import { nameOf } from '@luxon/formatters';
import { IChartDataKey } from '@luxon/components/chart/Chart';
import { useScreenSize, useLocalStorageContext } from '@luxon/hooks'
import { useGetMetrics, useExportSingleBillboardMetrics, MetricsSource } from '@luxon/hooks/api/MetricsApi'
import { ExportMetricsModal, IExportMetricsForm } from '@luxon/modals/ExportMetricsModal';

import { MetricSquares } from './MetricSquares';
import { Dayjs } from 'dayjs';

export const GetObjectCountMetricDataKeys = (color: 'default' | TColor): IChartDataKey[] => [
    {
        dataKey: nameOf<IObjectCountMetric>('carCount'),
        color: color === 'default' ? 'primary' : color,
        legendName: 'Cars'
    },
    {
        dataKey: nameOf<IObjectCountMetric>('busCount'),
        color: color === 'default' ? 'secondary' : color,
        legendName: 'Taxis'
    },
    {
        dataKey: nameOf<IObjectCountMetric>('truckCount'),
        color: color === 'default' ? 'warning' : color,
        legendName: 'Trucks'
    },
    {
        dataKey: nameOf<IObjectCountMetric>('motorbikeCount'),
        color: color === 'default' ? 'error' : color,
        legendName: 'Motorbikes'
    },
    // {
    //     dataKey: nameOf<IObjectCountMetric>('bicycleCount'),
    //     color: color === 'default' ? 'success' : color,
    //     legendName: 'Bicycles'
    // },
    {
        dataKey: nameOf<IObjectCountMetric>('personCount'),
        color: color === 'default' ? 'tertiary' : color,
        legendName: 'Pedestrians'
    }
];

interface IRangeOverride {
    startDate: Dayjs;
    endDate: Dayjs;
}

interface IObjectCountMetricsProps {
    itemIds: string[];
    metricsSource: 'assets' | 'billboards';
    onPageLoadError: () => void;
    fullHeight?: boolean;
    loadingStateChanged?: (isLoading: boolean) => void;
    chartHeightRatio?: number;
    hideSquares?: boolean;
    metricsChanged?: (metrics: IObjectCountMetric[]) => void;
    excludedChartTypes?: TChartType[];
    hideRangeSelection?: boolean;
    chartTypeChanged?: (chartType: TChartType) => void;
    legendsChanged?: (legends: TChartLegend[]) => void;
    rangeOverride?: IRangeOverride;
    exportable?: boolean;
    clientIdOverride?: string;
}
export const ObjectCountMetrics: FunctionComponent<IObjectCountMetricsProps> = (props: IObjectCountMetricsProps) => {

    const {
        itemIds,
        metricsSource,
        rangeOverride,
        onPageLoadError,
        metricsChanged,
        loadingStateChanged,
        legendsChanged,
        chartTypeChanged,
        excludedChartTypes,
        fullHeight,
        hideRangeSelection,
        exportable,
        hideSquares,
        chartHeightRatio,
        clientIdOverride
    } = props;
    const { isMobile, isSmallDesktop } = useScreenSize();
    const { metricsRange } = useLocalStorageContext();

    const { data: metrics, isFetching: metricsLoading } = useGetMetrics({
        source: metricsSource === 'assets' ? MetricsSource.LDSV1 : MetricsSource.Billboard,
        clientIdOverride,
        request: {
            itemIds: itemIds,
            range: !!rangeOverride ? 'Custom' : metricsRange.range,
            rangeStartDateTime: rangeOverride?.startDate ?? metricsRange.startDateTime,
            rangeEndDateTime: rangeOverride?.endDate ?? metricsRange.endDateTime,
            timeZoneOffsetMinutes: new Date().getTimezoneOffset() * -1
        }
    }, {
        enabled: !!itemIds && itemIds.length > 0 && !!metricsSource,
        refetchInterval: 60000,
        keepPreviousData: true,
        staleTime: 60000,
        onError: () => onPageLoadError ? onPageLoadError() : null
    });

    const [chartType, setChartType] = useState<TChartType>('area');
    const [chartLegends, setChartLegends] = useState<TChartLegend[]>(['Cars', 'Motorbikes', 'Taxis', 'Trucks', 'Pedestrians']);
    const [availableChartTypes, setAvailableChartTypes] = useState<TChartType[]>([]);
    const chartTypeOptions = useMemo<IMenuItem[]>(() => {
        return availableChartTypes.map(x => ({
            value: x,
            text: `${x.substring(0, 1).toUpperCase()}${x.substring(1)}`
        }) as IMenuItem);
    }, [availableChartTypes]);
    
    const [metricDataKeys] = useState<IChartDataKey[]>(GetObjectCountMetricDataKeys('default'));

    const { mutate: exportSingleBillboardMetrics, isLoading: exportSingleBillboardMetricsLoading } = useExportSingleBillboardMetrics();
    const [isExportMetricsModalOpen, setIsExportMetricsModalOpen] = useState(false);

    const exportMetrics = (data: IExportMetricsForm) => {
        setIsExportMetricsModalOpen(false);
        exportSingleBillboardMetrics({
            billboardId: itemIds[0],
            request: {
                comparingChartType: 'bar',
                legends: chartLegends,
                range: metricsRange.range,
                timelineChartType: chartType,
                timeZoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
                rangeEndDateTime: metricsRange.endDateTime,
                rangeStartDateTime: metricsRange.startDateTime,
                includeAverageFrequency: data.includeAverageFrequency,
                includeCostPerThousand: data.includeCostPerThousand,
                discountedRate: data.discountedRate,
                averageFrequency: data.averageFrequency
            }
        });
    };

    const handleChartLegendsChanged = (legends: TChartLegend[]) => {
        setChartLegends(legends);
        if (legendsChanged) {
            legendsChanged(legends);
        }
    };

    useEffect(() => {
        const chartTypes: TChartType[] = ['area', 'line', 'bar', 'pie', 'radar'];
        if (excludedChartTypes?.length > 0) {
            for (const type of excludedChartTypes) {
                chartTypes.splice(chartTypes.indexOf(type), 1);
            }
        }
        setAvailableChartTypes(chartTypes);
    }, [excludedChartTypes]);

    useEffect(() => {
        if (loadingStateChanged) {
            loadingStateChanged(metricsLoading);
        }
    }, [metricsLoading, loadingStateChanged]);

    useEffect(() => {
        if (metricsChanged && metrics) {
            metricsChanged(metrics);
        }
    }, [metrics, metricsChanged]);

    if (!metrics) {
        return <PageFrameSkeletonLoader />
    }

    return (
        <Stack display='flex' flexDirection='column' rowGap={3} sx={{
            height: fullHeight ? '100%' : null
        }}>
            {
                (!hideRangeSelection || exportable) && (
                    <Stack direction='row' justifyContent='space-between'>
                        {
                            !hideRangeSelection && (
                                <MetricsRange
                                    disabled={metricsLoading}
                                    showLoading={metricsLoading}
                                />
                            )
                        }

                        {
                            exportable && (
                                <Button
                                    variant='text'
                                    menuButtonOptions={[
                                        {
                                            text: 'Tip: You can toggle the chart legends to include or exclude them from the report',
                                            disabled: true,
                                            icon: <TipsAndUpdates />
                                        },
                                        { isDivider: true },
                                        { text: 'Landscape', icon: <CropLandscape /> },
                                    ]}
                                    menuButtonOptionClicked={() => setIsExportMetricsModalOpen(true)}
                                    loading={exportSingleBillboardMetricsLoading}
                                >
                                    Export
                                </Button>
                            )
                        }
                    </Stack>
                )
            }

            {
                !hideSquares && (
                    <MetricSquares metrics={metrics} isLoading={metricsLoading} />
                )
            }
            
            <Card sx={{flex: '1'}}>
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                    height: fullHeight ? '100%' : null,
                    minHeight: isMobile || isSmallDesktop ? '320px' : '0px'
                }}>
                    <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' flexWrap='wrap' columnGap={3} rowGap={2}>
                        <Typography variant='h5'>Impact Timeline</Typography>
                        <Select
                            label='View'
                            options={chartTypeOptions}
                            size='small'
                            margin='none'
                            value={chartType}
                            maxWidth='150px'
                            minWidth='150px'
                            onChange={(newVal: TChartType) => {
                                setChartType(newVal);
                                if (chartTypeChanged) {
                                    chartTypeChanged(newVal);
                                }
                            }}
                        />
                    </Stack>

                    <Chart
                        data={metrics}
                        type={chartType}
                        heightRatio={fullHeight ? null : (chartHeightRatio ?? 0.5)}
                        fullHeight={fullHeight}
                        xAxisLabel={(data: IObjectCountMetric) => data.metricDateTime.format(metricsRange.dateFormat)}
                        dataKeys={metricDataKeys}
                        hideGrid
                        chartColor={chartType === 'radar' ? 'lightGrey' : 'light'}
                        legendsChanged={handleChartLegendsChanged}
                    />
                </CardContent>
            </Card>

            {
                exportable && (
                    <ExportMetricsModal
                        open={isExportMetricsModalOpen}
                        onClose={() => setIsExportMetricsModalOpen(false)}
                        onSubmit={exportMetrics}
                        billboardId={itemIds[0]}
                    />
                )
            }
        </Stack>
    )
}