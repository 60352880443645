import { ROUTES } from "@luxon/constants";
import { IClient, IUser } from "@luxon/interfaces";

export const calculateHomePage = (user: IUser, client: IClient): string => {
  if (user.isSystemsUser) {
      return ROUTES.Clients;
  } else if (client.status === 'Onboarding') {
      return ROUTES.ClientOnboarding;
  } else if (client.status === 'Suspended' && client.suspendedReason === 'NoPayment') {
      return ROUTES.Invoices;
  } else if (client.clientType === 'Agency') {
      return ROUTES.SiteOwners;
  }

  return ROUTES.Analytics;
}