import React, { FunctionComponent, useState, useEffect } from 'react';

import { Stack } from '@mui/material';

import { Select, ProgressSpinner, FormV2, FormDateInput } from '@luxon/components';
import { IMetricsRangeForm, TMetricsRange } from '@luxon/interfaces';
import { DATE_FORMATS } from '@luxon/constants';
import { useLocalStorageContext } from '@luxon/hooks';

import dayjs, { Dayjs } from 'dayjs';

interface IMetricsRangeProps {
    disabled: boolean;
    showLoading: boolean;
}
const MetricsRange: FunctionComponent<IMetricsRangeProps> = (props: IMetricsRangeProps) => {

    const { metricsRange, setMetricsRange, resetMetricsRange } = useLocalStorageContext();
    const [dateNow] = useState<Dayjs>(dayjs().endOf('day'));

    const setStartDate = (newDate: Dayjs) => {
        const updated: IMetricsRangeForm = {...metricsRange,
            startDateTime: newDate,
            endDateTime: newDate.isAfter(metricsRange.endDateTime) ? newDate.add(1, 'day') : metricsRange.endDateTime
        };
        setDateFormatAndUpdate(updated);
    };
    const setEndDate = (newDate: Dayjs) => {
        const updated: IMetricsRangeForm = {...metricsRange,
            endDateTime: newDate,
            startDateTime: newDate.isBefore(metricsRange.startDateTime) ? newDate.add(-1, 'day') : metricsRange.startDateTime
        };
        setDateFormatAndUpdate(updated);
    };
    const setNewRange = (newRange: TMetricsRange) => {
        const updated: IMetricsRangeForm = {...metricsRange, range: newRange};
        setDateFormatAndUpdate(updated);
    }

    const setDateFormatAndUpdate = (updated: IMetricsRangeForm) => {
        if (updated.range === 'Custom') {
            updated.dateFormat = updated.endDateTime.diff(updated.startDateTime, 'days') <= 7 ? DATE_FORMATS.DayMonthHour : DATE_FORMATS.DayMonth;
        } else if (updated.range === 'PastDay' || updated.range === 'PastWeek') {
            updated.dateFormat = DATE_FORMATS.DayMonthHour;
        } else {
            updated.dateFormat = DATE_FORMATS.DayMonth;
        }
        setMetricsRange(updated);
    }

    useEffect(() => {
        resetMetricsRange();
    }, [resetMetricsRange]);

    return (
        <Stack display='flex' flexDirection='row' columnGap={2} alignItems='center'>
            {
                props.showLoading && (
                    <ProgressSpinner size={30} />
                )
            }

            {
                metricsRange.range === 'Custom' && (
                    <FormV2
                        formData={metricsRange}
                        formDataChanged={(fd, changedKey) => {
                            if (changedKey === 'startDateTime') {
                                setStartDate(fd.startDateTime);
                            } else if (changedKey === 'endDateTime') {
                                setEndDate(fd.endDateTime);
                            }
                        }}
                        disabled={props.disabled}
                        onSubmit={() => null}
                    >
                        <Stack display='flex' flexDirection='row' columnGap={1} alignItems='center' justifyContent='flex-end'>
                            <FormDateInput<IMetricsRangeForm>
                                name='startDateTime'
                                format={DATE_FORMATS.DayMonthYear}
                                label='From'
                                maxDate={dateNow}
                                margin='none'
                                sx={{
                                    width: '180px'
                                }}
                                
                            />

                            <FormDateInput<IMetricsRangeForm>
                                name='endDateTime'
                                format={DATE_FORMATS.DayMonthYear}
                                label='To'
                                maxDate={dateNow}
                                margin='none'
                                sx={{
                                    width: '180px'
                                }}
                                
                            />
                        </Stack>
                    </FormV2>
                )
            }

            <Select
                options={[
                    { text: '24 Hours', value: 'PastDay' },
                    { text: 'Week', value: 'PastWeek' },
                    { text: '30 Days', value: 'PastMonth' },
                    { text: '90 Days', value: 'PastQuarter' },
                    { text: 'Custom', value: 'Custom' }
                ]}
                disabled={props.disabled}
                value={metricsRange.range}
                onChange={setNewRange}
                minWidth='200px'
                maxWidth='200px'
                maxHeight='1000px'
                margin='none'
            />
        </Stack>
    )
};

export default MetricsRange;