import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { IReducerAction, IClient } from '@luxon/interfaces';
import { useGetClient } from '@luxon/hooks/api/ClientApi';

type TClientContextActions =
    'SET_CLIENT' |
    'SET_INITIALIZED';

interface IClientContext {
    client?: IClient;
    loggedIn?: boolean;
    initialized?: boolean;
    setClient?: (client: IClient) => void;
}

const initialState: IClientContext = {
    initialized: false,
    client: null,
    loggedIn: false
};
export const ClientContext = React.createContext<IClientContext>(initialState);

const ClientContextReducer = (state: IClientContext, action: IReducerAction<TClientContextActions>): IClientContext => {
    switch (action.type) {
        case 'SET_CLIENT':
            return {...state, client: action.payload, loggedIn: action.payload?.id?.length > 0 };
        case 'SET_INITIALIZED':
            return {...state, initialized: true };
        default:
            return state;
    }
};

const providerActions = {
    setClient: (dispatcher: React.Dispatch<IReducerAction<TClientContextActions>>, client: IClient) => {
        dispatcher({ type: 'SET_CLIENT', payload: client });
    }
};

interface IClientProviderProps {
    children: any;
};
const CLIENT_LOCAL_STORAGE_KEY = 'client';
export const ClientContextProvider: FunctionComponent<IClientProviderProps> = (props: IClientProviderProps) => {
    const [state, dispatch] = useReducer(ClientContextReducer, {...initialState,
        setClient: (client: IClient) => providerActions.setClient(dispatch, client)
    });

    useGetClient(state.client?.id, false, {
        autoShowErrorMessages: false,
        autoLogOutOnAuthError: window.location.pathname !== '/',
        onSuccess: (client) => {
            dispatch({
                type: 'SET_CLIENT',
                payload: client
            });
        }
    });

    useEffect(() => {
        const clientLocalStore = localStorage.getItem(CLIENT_LOCAL_STORAGE_KEY);
        if (clientLocalStore) {
            const client: IClient = JSON.parse(clientLocalStore);
            dispatch({
                type: 'SET_CLIENT',
                payload: client
            });
        }
        dispatch({ type: 'SET_INITIALIZED' });
    }, []);

    useEffect(() => {
        if (!state.client) {
            localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEY);
        } else {
            localStorage.setItem(CLIENT_LOCAL_STORAGE_KEY, JSON.stringify(state.client));
        }

        window.newrelic?.setCustomAttribute('clientId', state.client?.id);
    }, [state.client]);

    return (
        <ClientContext.Provider value={state}>
            {props.children}
        </ClientContext.Provider>
    );
}