import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { IBillboard, ICreateOrUpdateBillboardRequest, IDefaultResponseMessage, IGetBillboardsMapRequest, IPaginationWrapper, ISearchClientBillboardsRequest } from '@luxon/interfaces';
import { HttpClientError, QueryKeys, getQuerySettings, IQuerySettings } from '@luxon/models';
import { useHttpClient, useSnackbar } from '@luxon/hooks';

interface IRequest<T> {
  billboardId: string;
  request?: T;
}

enum BillboardQueryKey {
  Map = 'Map',
  ClientBillboards = 'ClientBillboards'
}

export const useGetBillboard = (billboardId: string | undefined, settings?: IQuerySettings) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IBillboard, HttpClientError>([QueryKeys.Billboards, billboardId], () => {
        return GET<IBillboard>(`/v1/billboards/${billboardId}`);
    }, {
      enabled: !!billboardId && querySettings.enabled,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError
    });
}

export const useSearchClientBillboards = (clientId: string, request: ISearchClientBillboardsRequest, settings?: IQuerySettings) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IPaginationWrapper<IBillboard>, HttpClientError>([QueryKeys.Billboards, BillboardQueryKey.ClientBillboards, clientId, request], () => {
        return GET<IPaginationWrapper<IBillboard>>(`/v1/clients/${clientId}/billboards`, request);
    }, {
      ...querySettings,
      enabled: querySettings.enabled && Boolean(clientId) && Boolean(request),
    });
}

export const useGetBillboardsMap = (request: IGetBillboardsMapRequest, settings?: IQuerySettings) => {
    const { POST } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IBillboard[], HttpClientError>([QueryKeys.Billboards, BillboardQueryKey.Map, request], () => {
        return POST<IBillboard[]>(`/v1/billboards/map`, request);
    }, {
      enabled: !!request && querySettings.enabled,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError,
      keepPreviousData: settings.keepPreviousData
    });
}

export const useCreateBillboard = (navigateAfterCreate?: (billboard: IBillboard) => string) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createBillboard = (request: ICreateOrUpdateBillboardRequest) => {
    return POST<IBillboard>(`/v1/billboards`, request);
  };

  return useMutation<IBillboard, HttpClientError, ICreateOrUpdateBillboardRequest>(createBillboard, {
    onSuccess: (response) => {
      queryClient.setQueryData([QueryKeys.Billboards, response.id], response);
      showSnackbar('Billboard successfully created');

      if (navigateAfterCreate) {
        navigate(navigateAfterCreate(response), { replace: true });
      }
    }
  });
}

export const useUpdateBillboard = () => {
  const { PUT } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateBillboard = ({ billboardId, request }: IRequest<ICreateOrUpdateBillboardRequest>) => {
    return PUT<IBillboard>(`/v1/billboards/${billboardId}`, request);
  };

  return useMutation<IBillboard, HttpClientError, IRequest<ICreateOrUpdateBillboardRequest>>(updateBillboard, {
    onSuccess: (response, input) => {
      queryClient.setQueryData([QueryKeys.Billboards, input.billboardId], response);
      showSnackbar('Billboard successfully updated');
    }
  });
}

export const useDeleteBillboard = (navigateAfterDelete?: (billboardId: string) => string) => {
  const { DELETE } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteBillboard = ({ billboardId, request }: IRequest<{ password: string }>) => {
    return DELETE<IDefaultResponseMessage>(`/v1/billboards/${billboardId}`, { p: btoa(request.password) });
  };

  return useMutation<IDefaultResponseMessage, HttpClientError, IRequest<{ password: string }>>(deleteBillboard, {
    onSuccess: (response, input) => {
      queryClient.invalidateQueries([QueryKeys.Billboards, input.billboardId]);
      showSnackbar('Billboard successfully deleted');

      if (navigateAfterDelete) {
        navigate(navigateAfterDelete(input.billboardId), { replace: true });
      }
    }
  });
}