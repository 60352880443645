import React, { FunctionComponent } from 'react';

import { Tabs as MuiTabs, Box, Tab } from '@mui/material';

import { IconText } from './IconText';

export interface ITabOption {
    label: string;
    icon?: React.ReactNode;
    iconSuffix?: React.ReactNode;
}

interface ITabsProps {
    activeIndex: number;
    options: ITabOption[];
    onChange: (newIndex: number) => void;
    disabled?: boolean;
}
export const Tabs: FunctionComponent<ITabsProps> = (props: ITabsProps) => {

    const {
        activeIndex,
        options,
        onChange,
        disabled
    } = props;

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '25px' }}>
            <MuiTabs
                value={activeIndex}
                onChange={(e, newVal) => onChange(newVal)}
                variant={'scrollable'}
                scrollButtons='auto'
            >
                {
                    options.map((option, index) => (
                        <Tab
                            key={index}
                            disabled={disabled}
                            label={(
                                <IconText
                                    icon={option.icon}
                                    iconSuffix={option.iconSuffix}
                                >
                                    {option.label}
                                </IconText>
                            )}
                        />
                    ))
                }
            </MuiTabs>
        </Box>
    )
};