import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material'

import { Form, Button, Checkbox, Input, FormInputsOutlet, IFormInput, IFormSubmitResult } from '@luxon/components';
import { formatCurrency, nameOf } from '@luxon/formatters';
import { FeatureFlag } from '@luxon/models';
import { useAppSettings, useClientContext, useUserContext } from '@luxon/hooks';
import { useGetBillboard } from '@luxon/hooks/api/BillboardApi';
import { useGetClient } from '@luxon/hooks/api/ClientApi';

export interface IExportMetricsForm {
  includeAverageFrequency: boolean;
  averageFrequency?: number;
  includeCostPerThousand: boolean;
  discountedRate?: number;
  discountedRatePercentage?: number;
}
const initialFormState = (): IExportMetricsForm => ({
  includeAverageFrequency: false,
  averageFrequency: null,
  includeCostPerThousand: false,
  discountedRate: null,
  discountedRatePercentage: null
});

interface IExportMetricsModalProps {
  open: boolean;
  billboardId: string;
  onClose: () => void;
  onSubmit: (data: IExportMetricsForm) => void;
  clientId?: string;
}
export const ExportMetricsModal: FunctionComponent<IExportMetricsModalProps> = (props: IExportMetricsModalProps) => {

  const {
    open,
    billboardId,
    onClose,
    onSubmit,
    clientId
  } = props;

  const { client: loggedInClient } = useClientContext();
  const { user } = useUserContext();
  const { isFeatureEnabled } = useAppSettings();

  const { data: billboardClient } = useGetClient(clientId, false, {
    enabled: !user.isSystemsUser && Boolean(clientId) && loggedInClient.clientType === 'Agency'
  });

  const { data: billboard, isLoading: billboardLoading } = useGetBillboard(billboardId, {
    enabled: user.isSystemsUser
  });
  const billboardDefaultRate = useMemo<number | null>(() => {
    if (user.isSystemsUser && !!billboard) {
      return billboard.monthlyRate ?? null;
    } else if (billboardClient?.billboards?.find(x => x.id === billboardId)) {
      return billboardClient?.billboards?.find(x => x.id === billboardId).monthlyRate ?? null;
    } else if (!user.isSystemsUser && loggedInClient.billboards?.find(x => x.id === billboardId)) {
      return loggedInClient.billboards.find(x => x.id === billboardId).monthlyRate ?? null;
    }

    return null;
  }, [loggedInClient, billboard, user, billboardId, billboardClient]);

  const handleDiscountedRateChanged = (newVal: number) => {
    if (newVal === 0) {
      setFormData(fd => ({...fd, discountedRatePercentage: 100}));
    } else if (!newVal) {
      setFormData(fd => ({...fd, discountedRatePercentage: 0 }));
    } else {
      let percentage = Math.ceil((billboardDefaultRate - newVal) / billboardDefaultRate * 10000) / 100;
      setFormData(fd => ({...fd, discountedRatePercentage: percentage}));
    }
  }

  const handleDiscountedRatePercentageChanged = (newPercentage: number) => {
    if (!newPercentage) {
      setFormData(fd => ({...fd, discountedRate: billboardDefaultRate }));
    } else {
      let newVal = Math.ceil((billboardDefaultRate - (billboardDefaultRate * (newPercentage / 100))) * 100) / 100;
      setFormData(fd => ({...fd, discountedRate: newVal}));
    }
  }

  const [formData, setFormData] = useState<IExportMetricsForm>(initialFormState());
  const formInputs: IFormInput[] = useMemo(() => [
    {
      label: 'Include Average Frequency',
      name: nameOf<IExportMetricsForm>('includeAverageFrequency'),
      component: <Checkbox />
    },
    {
      label: 'Average Frequency',
      name: nameOf<IExportMetricsForm>('averageFrequency'),
      component: <Input type='number' min={0} />,
      shouldRender: formData.includeAverageFrequency
    },
    {
      label: 'Include Cost Per Thousand',
      name: nameOf<IExportMetricsForm>('includeCostPerThousand'),
      component: <Checkbox />,
      shouldRender: isFeatureEnabled(FeatureFlag.COST_PER_THOUSAND_ENABLED),
      disabled: !billboardDefaultRate
    },
    {
      controlType: 'custom',
      component: (
        <Typography variant='caption' className='color-warning'>
          Please first setup site rate before using this feature
        </Typography>
      ),
      shouldRender: !billboardDefaultRate && !billboardLoading && isFeatureEnabled(FeatureFlag.COST_PER_THOUSAND_ENABLED)
    },
    {
      controlType: 'custom',
      component: (
        <Typography paddingTop='25px'>
          Monthly Rate: <b>{formatCurrency(billboardDefaultRate)}</b>
        </Typography>
      ),
      shouldRender: formData.includeCostPerThousand && isFeatureEnabled(FeatureFlag.COST_PER_THOUSAND_ENABLED)
    },
    {
      label: 'Discounted Rate',
      name: nameOf<IExportMetricsForm>('discountedRate'),
      component: <Input type='number' min={0} startIcon={<span>R</span>} />,
      shouldRender: formData.includeCostPerThousand && isFeatureEnabled(FeatureFlag.COST_PER_THOUSAND_ENABLED),
      gridWidth: { xs: 6 }
    },
    {
      label: 'Discounted %',
      name: nameOf<IExportMetricsForm>('discountedRatePercentage'),
      component: <Input type='number' min={0} max={100} endIcon={<span>%</span>} />,
      shouldRender: formData.includeCostPerThousand && isFeatureEnabled(FeatureFlag.COST_PER_THOUSAND_ENABLED),
      gridWidth: { xs: 6 }
    }
  ] as IFormInput[], [formData, isFeatureEnabled, billboardDefaultRate, billboardLoading]);

  const handleFormDataChanged = (updatedFormData: IExportMetricsForm) => {
    setFormData(fd => ({...fd, ...updatedFormData}));

    if (updatedFormData.discountedRatePercentage !== formData.discountedRatePercentage) {
      handleDiscountedRatePercentageChanged(updatedFormData.discountedRatePercentage);
    } else if (updatedFormData.discountedRate !== formData.discountedRate) {
      handleDiscountedRateChanged(updatedFormData.discountedRate);
    }
  }

  const handleSubmit = (submitResult: IFormSubmitResult) => {
    if (!submitResult.valid) {
      return;
    }

    const fixDecimal = (rawDecimal: number | undefined): number => {
      return parseFloat((rawDecimal ?? 0).toString().replace(',', '.'));
    };

    const data = submitResult.getData<IExportMetricsForm>();
    data.averageFrequency = fixDecimal(data.averageFrequency);
    data.discountedRate = fixDecimal(data.discountedRate);
    data.discountedRatePercentage = fixDecimal(data.discountedRatePercentage);
    onSubmit(data);
    onClose();
  };

  useEffect(() => {
    setFormData(initialFormState());
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Form
        inputs={formInputs}
        formData={formData}
        formDataChanged={handleFormDataChanged}
        mode='reactive'
        onSubmit={handleSubmit}
      >
        <DialogTitle>Export Metrics</DialogTitle>
        <DialogContent>
          <FormInputsOutlet />
        </DialogContent>
        <DialogActions>
          <Button
              variant='text'
              color='error'
              onClick={onClose}
          >
              Cancel
          </Button>
          <Button
              variant='text'
              type='submit'
          >
              Export
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}