import { FunctionComponent, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { Stack } from '@mui/material';
import { Cached } from '@mui/icons-material';

import { UserActionPageFrame, Button } from '@luxon/components';
import { logger } from '@luxon/services';

const RouterErrorPage: FunctionComponent = () => {

    const routeError = useRouteError();

    useEffect(() => {
        logger.error('Router Error Caught: ', routeError);
    }, [routeError]);

    return (
        <UserActionPageFrame
            title='Oops'
            subTitle='Something went wrong while trying to load the page. Please refresh your page, or contact Luxon support if issue persists.'
            errorMessages={[]}
            formInputs={[]}
            isLoading={false}
            onSubmit={() => null}
            submitIcon={null}
            submitText={null}
            body={
                <Stack display='flex' justifyContent='center' alignItems='center' flex='1' sx={{marginTop: '25px'}}>

                    <Button
                        icon={<Cached />}
                        onClick={() => window.location.reload()}
                    >
                        Refresh
                    </Button>
                </Stack>
            }
        />
    )
};

export default RouterErrorPage;