import React, { FunctionComponent, useReducer, useEffect, useCallback } from 'react';

import { IReducerAction, IUser, TUserRole } from '@luxon/interfaces';
import { useGetSelf } from '@luxon/hooks/api/UserApi';

type TUserContextActions =
    'SET_USER' |
    'SET_INITIALIZED';

interface IUserContext {
    user?: IUser;
    userHasRole: (...roles: TUserRole[]) => boolean;
    loggedIn: boolean;
    initialized: boolean;
    setUser: (user: IUser) => void;
}

const initialState: IUserContext = {
    initialized: false,
    loggedIn: false,
    setUser: () => null,
    userHasRole: () => false
};
export const UserContext = React.createContext<IUserContext>(initialState);

const UserContextReducer = (state: IUserContext, action: IReducerAction<TUserContextActions>): IUserContext => {
    switch (action.type) {
        case 'SET_USER':
            return {...state, user: action.payload, loggedIn: action.payload?.id?.length > 0 };
        case 'SET_INITIALIZED':
            return {...state, initialized: true };
        default:
            return state;
    }
};

const providerActions = {
    setUser: (dispatcher: React.Dispatch<IReducerAction<TUserContextActions>>, user: IUser) => {
        dispatcher({ type: 'SET_USER', payload: user });
    }
};

interface IUserProviderProps {
    children: any;
};
const USER_LOCAL_STORAGE_KEY = 'user';
export const UserContextProvider: FunctionComponent<IUserProviderProps> = (props: IUserProviderProps) => {
    const [state, dispatch] = useReducer(UserContextReducer, {...initialState,
        setUser: (user: IUser) => providerActions.setUser(dispatch, user)
    });

    useGetSelf({
        autoShowErrorMessages: false,
        autoLogOutOnAuthError: false,
        enabled: !!state.user?.id,
        onSuccess: (response) => dispatch({ type: 'SET_USER', payload: response }),
        onError: (error) => {
            if (error.statusCode === 401 || error.statusCode === 403) {
                window.location.href = `/sign-out/${state.user?.id ?? 'null'}`;
            }
        } 
    });

    const userHasRole = useCallback((...roles: TUserRole[]) => {
        return roles.includes(state.user?.role) ?? false;
    }, [state]);

    useEffect(() => {
        const userLocalStore = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
        if (userLocalStore) {
            const user: IUser = JSON.parse(userLocalStore);
            dispatch({
                type: 'SET_USER',
                payload: user
            });
        }
        dispatch({ type: 'SET_INITIALIZED' });
    }, [])

    useEffect(() => {
        if (!state.user) {
            localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
        } else {
            localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(state.user));
        }

        window.newrelic?.setCustomAttribute('userId', state.user?.id);
    }, [state.user]);

    return (
        <UserContext.Provider value={{...state, userHasRole}}>
            {props.children}
        </UserContext.Provider>
    );
}