const PAGE_TITLE_SUFFIX = 'Luxon';

export const usePageTitle = () => {

  const setPageTitle = (title?: string) => {
    if (title) {
      document.title = `${title} | ${PAGE_TITLE_SUFFIX}`;
    } else {
      document.title = PAGE_TITLE_SUFFIX;
    }
  };

  return {
    setPageTitle,
    PAGE_TITLE_SUFFIX
  }
}