import { FunctionComponent } from 'react';

import { TUserRole, IUser, IClient } from '@luxon/interfaces';
import { useClientContext, useUserContext } from '@luxon/hooks';

interface IRoleVisibilityProps {
    visibleRoles?: TUserRole[];
    notVisibleRoles?: TUserRole[];
    visible?: (user: IUser, client: IClient) => boolean;
    notVisible?: (user: IUser, client: IClient) => boolean;
    notVisibleContent?: React.ReactNode;
    children: any;
}
const RoleVisibility: FunctionComponent<IRoleVisibilityProps> = (props: IRoleVisibilityProps) => {

    const {
        visibleRoles,
        notVisibleRoles,
        visible,
        notVisible,
        notVisibleContent,
        children
     } = props;

    const { user } = useUserContext();
    const { client } = useClientContext();

    if (visibleRoles?.length > 0 && visibleRoles.indexOf(user.role) < 0) {
        return notVisibleContent;
    } else if (notVisibleRoles?.length > 0 && notVisibleRoles.indexOf(user.role) >= 0) {
        return notVisibleContent;
    } else if (visible && typeof visible === 'function' && !visible(user, client)) {
        return notVisibleContent;
    } else if (notVisible && typeof notVisible === 'function' && notVisible(user, client)) {
        return notVisibleContent;
    }

    return children;
};

export default RoleVisibility;