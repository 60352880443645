export enum QueryKeys {
  AppSettings = 'AppSettingsQueryKey',
  Billboards = 'BillboardsQueryKey',
  SupportTickets = 'SupportTicketsQueryKey',
  Clients = 'ClientsQueryKey',
  Users = 'UsersQueryKey',
  Assets = 'AssetsQueryKey',
  AssetPlannedInstallations = 'AssetPlannedInstallationsQueryKey',
  ClientInvoices = 'ClientInvoicesQueryKey',
  ClientCampaigns = 'ClientCampaignsQueryKey',
  Metrics = 'MetricsQueryKey',
  AgencyClientsList = 'AgencyClientsListQueryKey',
  AgencyClientBillboardActivationRequests = 'AgencyClientBillboardActivationRequests'
}