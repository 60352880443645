import { useState, useEffect, FunctionComponent, useRef } from 'react';

import { useThemePalette } from '@luxon/hooks';
import { IGeoPoint } from '@luxon/interfaces';

export type TGeoMarkerIcon = 'CAMERA' | 'BILLBOARD';

export interface IGeoMarker<T = any> {
    position: IGeoPoint;
    icon: TGeoMarkerIcon;
    color?: 'light' | 'success' | 'warning' | 'error' | 'primary';
    editable?: boolean;
    focusOnMarker?: boolean;
    opacity?: number;
    zIndex?: number;
    title?: string;
    label?: string;
    data?: T;
    key?: any;
}

interface IGeoMarkerProps {
    map: google.maps.Map;
    marker: IGeoMarker;
    labelEnabled?: boolean;
    positionChanged: (position: IGeoPoint) => void;
    onMarkerClick?: (e: MouseEvent) => void;
}
export const GeoMarker: FunctionComponent<IGeoMarkerProps> = (props: IGeoMarkerProps) => {
    const themePalette = useThemePalette();

    const [marker, setMarker] = useState<google.maps.Marker>();
    const [markerListeners, setMarkerListeners] = useState<google.maps.MapsEventListener[]>([]);
    const isDraggingMarker = useRef(false);

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker({
                zIndex: 1,
                optimized: true
            }));
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);

    useEffect(() => {
        if (!marker) {
            return;
        }

        const currentMarkerPosition = marker.getPosition();
        const isMarkerDraggable = marker.getDraggable();
        
        if (!currentMarkerPosition || currentMarkerPosition.lat() !== props.marker.position.latitude || currentMarkerPosition.lng() !== props.marker.position.longitude) {
            marker.setPosition({
                lat: props.marker.position.latitude,
                lng: props.marker.position.longitude
            });
        }

        if (isMarkerDraggable !== (props.marker.editable ?? false)) {
            marker.setDraggable(props.marker.editable ?? false);
        }

        if (currentMarkerPosition && props.marker.focusOnMarker && !isDraggingMarker.current) {
            const mapCenter = props.map.getCenter();
            if (mapCenter.lat() !== currentMarkerPosition.lat() || mapCenter.lng() !== currentMarkerPosition.lng()) {
                props.map.panTo(currentMarkerPosition);
            }
        }

        if (props.marker.opacity && props.marker.opacity !== marker.getOpacity()) {
            marker.setOpacity(props.marker.opacity);
        }

        if ((props.marker.zIndex ?? -1) >= 0 && marker.getZIndex() !== props.marker.zIndex) {
            marker.setZIndex(props.marker.zIndex);
        }

        if (props.marker.title !== marker.getTitle()) {
            marker.setTitle(props.marker.title);
        }

        const iconName = (props.marker.icon ?? 'CAMERA').toLowerCase();
        const iconColor = (props.marker.color ?? 'primary');
        const iconPath = `/map-icons/luxon-${iconName}-map-icon-${iconColor}.png`;
        if (iconPath !== marker.getIcon()) {
            marker.setIcon(iconPath);
        }

        markerListeners.filter(x => x).forEach(x => x.remove());

        setMarkerListeners([
            marker.addListener('dragstart', () => isDraggingMarker.current = true),
            marker.addListener('dragend', () => {
                isDraggingMarker.current = false;
                const newPosition = marker.getPosition();
                props.positionChanged({
                    latitude: newPosition.lat(),
                    longitude: newPosition.lng()
                });
            }),
            marker.addListener('click', (e: google.maps.MapMouseEvent) => {
                if (props.onMarkerClick) {
                    props.onMarkerClick(e.domEvent as MouseEvent);
                }
            })
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marker, props.marker, themePalette, props.onMarkerClick]);

    useEffect(() => {
        if (!marker) {
            return;
        }

        const markerLabel = marker.getLabel();
        if ((props.marker.label !== markerLabel) && props.labelEnabled) {
            marker.setLabel(props.marker.label ? {
                text: props.marker.label,
                className: 'geo-marker-label'
            } : '');
        } else if ((props.marker.label !== markerLabel) && !props.labelEnabled) {
            marker.setLabel(null);
        }
    }, [props.labelEnabled, props.marker.label, marker])

    useEffect(() => {
        if (!marker) {
            return;
        }

        marker.setMap(props.map);
    }, [marker, props.map]);

    return null;
};