import { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

interface IThemePalette {
    primary?: string;
    secondary?: string;
    text?: string;
    error?: string;
    success?: string;
    warning?: string;
}
export const useThemePalette = (): IThemePalette => {
    const [themePalette, setThemePalette] = useState<IThemePalette>({});

    const theme = useTheme();

    useEffect(() => {
        setThemePalette({
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            text: theme.palette.text.primary,
            error: theme.palette.error.main,
            success: theme.palette.success.main,
            warning: theme.palette.warning.main
        });
    }, [theme]);

    return themePalette;
}