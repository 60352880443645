import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { IReducerAction } from '@luxon/interfaces';

type TScreenSizeContextActions = 'SET_SCREEN_SIZE';

type TScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface IScreenSizeContext {
  currentSize: TScreenSize;
  isMobile: boolean;
  isTablet: boolean;
  isMobileOrTablet: boolean;
  isSmallDesktop: boolean;
  isLargeDesktop: boolean;
}

const initialState: IScreenSizeContext = {
  currentSize: 'xs',
  isMobile: true,
  isTablet: false,
  isMobileOrTablet: true,
  isSmallDesktop: false,
  isLargeDesktop: false
};
export const ScreenSizeContext = React.createContext<IScreenSizeContext>(initialState);

const ScreenSizeContextReducer = (state: IScreenSizeContext, action: IReducerAction<TScreenSizeContextActions>): IScreenSizeContext => {
  switch (action.type) {
    case 'SET_SCREEN_SIZE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

interface IScreenSizeContextProviderProps {
    children: any;
}
export const ScreenSizeContextProvider: FunctionComponent<IScreenSizeContextProviderProps> = (props: IScreenSizeContextProviderProps) => {
    const [state, dispatch] = useReducer(ScreenSizeContextReducer, {...initialState});

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));

    useEffect(() => {
      let updatedState: IScreenSizeContext | null = null;
      if (isXS && state.currentSize !== 'xs') {
        updatedState = { currentSize: 'xs', isMobile: true, isMobileOrTablet: true, isTablet: false, isSmallDesktop: false, isLargeDesktop: false };
      } else if (isSM && state.currentSize !== 'sm') {
        updatedState = { currentSize: 'sm', isMobile: false, isTablet: true, isMobileOrTablet: true, isSmallDesktop: false, isLargeDesktop: false };
      } else if (isMD && state.currentSize !== 'md') {
        updatedState = { currentSize: 'md', isMobile: false, isTablet: false, isMobileOrTablet: false, isSmallDesktop: true, isLargeDesktop: false };
      } else if (isLG && state.currentSize !== 'lg') {
        updatedState = { currentSize: 'lg', isMobile: false, isTablet: false, isMobileOrTablet: false, isSmallDesktop: true, isLargeDesktop: false };
      } else if (isXL && state.currentSize !== 'xl') {
        updatedState = { currentSize: 'xl', isMobile: false, isTablet: false, isMobileOrTablet: false, isSmallDesktop: false, isLargeDesktop: true };
      }

      if (updatedState) {
        dispatch({
          type: 'SET_SCREEN_SIZE',
          payload: updatedState
        });
      }
    }, [isXS, isSM, isMD, isLG, isXL, state]);

  return (
    <ScreenSizeContext.Provider value={state}>
      {props.children}
    </ScreenSizeContext.Provider>
  )
};