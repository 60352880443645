import { FunctionComponent, useState, useEffect, useMemo, useRef } from 'react';

import { TableCell, TableSortLabel, Popover, Typography, Stack, SxProps, Theme } from '@mui/material';

import { FilterAlt, FilterAltOff, Clear } from '@mui/icons-material';

import { Button, Input, Select } from '@luxon/components';
import { IMenuItem, TSortDirection } from '@luxon/interfaces';

type TFilterType = 'text' | 'number' | 'select';
export interface ITableColumn<TDataType = any> {
    title: string;
    dataKey?: keyof TDataType;
    sortable?: boolean;
    filterable?: boolean;
    filterType?: TFilterType;
    filterOptions?: IMenuItem[];
    filterPlaceholder?: string;
    element?: (dataRow: TDataType) => JSX.Element | string;
    disableRowClick?: boolean;
    allowWrapping?: boolean;
    visible?: boolean;
    headerSx?: SxProps<Theme>;
    maxWidth?: string;
}

interface ITableHeaderColumnProps {
    columnDef: ITableColumn;
    sortKey?: string;
    sortDirection?: TSortDirection;
    filterValue?: string;
    filterChanged?: (value: any) => void;
    sortingClicked?: () => void;
    disabled?: boolean;
}
export const TableHeaderColumn: FunctionComponent<ITableHeaderColumnProps> = (props: ITableHeaderColumnProps) => {

    const [filterValue, setFilterValue] = useState(props.filterValue ?? '');
    const filterValuePreview = useMemo(() => {
        let newFilterValuePreview = `${filterValue}`;

        if (props.columnDef.filterType === 'select' && props.columnDef.filterOptions?.length > 0) {
            const filterOption = props.columnDef.filterOptions.find(x => (x.value ?? x.text) === newFilterValuePreview);
            newFilterValuePreview = filterOption?.text ?? filterOption?.value ?? newFilterValuePreview;
        }

        if (newFilterValuePreview.length > 10) {
            return `${newFilterValuePreview.substring(0, 7)}...`;
        }
        return newFilterValuePreview;
    }, [filterValue, props.columnDef]);
    const [tempFilterValue, setTempFilterValue] = useState(props.filterValue ?? '');

    const filterButtonRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);

    const updateFilterValue = (val: string) => {
        setFilterValue(val);
        if (props.filterChanged) {
            let valToEmit: any = val;
            if (props.columnDef.filterType === 'number') {
                valToEmit = val && !isNaN(parseInt(val)) ? parseInt(val) : null;
            }
            props.filterChanged(valToEmit);
        }
    };

    const sortableHeaderColumn = (title: string) => (
        <TableSortLabel
            active={props.sortKey === props.columnDef.dataKey}
            direction={props.sortDirection === 'ASC' ? 'asc' : 'desc'}
            onClick={props.disabled ? null : props.sortingClicked}
        >
            {title}
        </TableSortLabel>
    );

    const inputLabel = props.columnDef.filterPlaceholder ?? `Filter by ${props.columnDef.title}`;

    const filterBlock = () => (
        <>
            <Button
                iconOnly={!filterValue}
                icon={filterValue ? <FilterAlt fontSize='small' /> : <FilterAltOff fontSize='small' />}
                size='small'
                color={filterValue ? 'primary' : 'lightGrey'}
                onClick={(e) => setFilterOpen(true)}
                variant='text'
                disabled={props.disabled}
                ariaLabel={inputLabel}
            >
                {
                    filterValue && (
                        <Typography variant='caption'>"{filterValuePreview}"</Typography>
                    )
                }
            </Button>
            <Popover
                open={filterOpen}
                anchorEl={filterButtonRef.current}
                onClose={() => setFilterOpen(false)}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <Stack rowGap='15px' style={{padding: '5px 20px', minWidth: '300px'}}>
                    {
                        props.columnDef.filterType === 'select' && props.columnDef.filterOptions?.length > 0 ? (
                            <Select
                                label={inputLabel}
                                options={props.columnDef.filterOptions}
                                value={tempFilterValue}
                                onChange={(newValue) => setTempFilterValue(newValue)}
                            />
                        ) : (
                            <Input
                                label={inputLabel}
                                value={tempFilterValue}
                                onChange={(newValue) => setTempFilterValue(newValue)}
                                type={props.columnDef.filterType === 'number' ? 'number' : 'text'}
                                endIcon={<Clear />}
                                endIconIsButton={true}
                                endIconButtonClicked={() => {
                                    setTempFilterValue('');
                                    updateFilterValue('');
                                }}
                                autoFocus={true}
                            />
                        )
                    }
                    <Stack direction='row' justifyContent='space-between'>
                        <Button
                            size='small'
                            variant='text'
                            color='error'
                            disabled={!tempFilterValue}
                            onClick={() => {
                                setTempFilterValue('');
                                updateFilterValue('');
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            size='small'
                            variant='text'
                            disabled={tempFilterValue === filterValue}
                            onClick={() => updateFilterValue(tempFilterValue)}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );

    useEffect(() => {
        setFilterOpen(false);
        setTempFilterValue(filterValue);
    }, [filterValue]);

    return (
        <TableCell ref={filterButtonRef} sx={props.columnDef.headerSx}>
            {props.columnDef.sortable ? sortableHeaderColumn(props.columnDef.title) : props.columnDef.title}
            {props.columnDef.filterable && filterBlock()}
        </TableCell>
    )
};