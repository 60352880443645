import React, { FunctionComponent } from 'react';
import { createTheme, PaletteColorOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

import palette from './palette.module.scss';

const defaultFontFamily = [
    '"Poppins"'
].join(',');
const headerFontFamily = defaultFontFamily.slice(0);

interface IAppThemeProps {
    children: any;
};
const AppTheme: FunctionComponent<IAppThemeProps> = (props: IAppThemeProps) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: palette.colorPrimary,
                contrastText: '#fff'
            },
            secondary: {
                main: palette.colorSecondary,
                contrastText: '#fff'
            },
            tertiary: {
                main: palette.colorTertiary
            },
            text: {
                primary: palette.colorText,
                disabled: palette.colorTextDisabled
            },
            error: {
                main: palette.colorError
            },
            success: {
                main: palette.colorSuccess
            },
            warning: {
                main: palette.colorWarning
            },
            light: {
                main: palette.colorLight
            },
            shade: {
                main: palette.colorShade
            },
            lightGrey: {
                main: palette.colorLightGrey
            }
        },
        typography: {
            fontFamily: defaultFontFamily,
            h1: {
                fontFamily: headerFontFamily
            },
            h2: {
                fontFamily: headerFontFamily
            },
            h3: {
                fontFamily: headerFontFamily
            },
            h4: {
                fontFamily: headerFontFamily
            },
            h5: {
                fontFamily: headerFontFamily
            },
            h6: {
                fontFamily: headerFontFamily
            },
            button: {
                fontFamily: defaultFontFamily,
                fontWeight: '700',
                fontSize: '15px',
                lineHeight: '20px',
                textTransform: 'none'
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    ':root': {
                        '--color-primary': palette.colorPrimary,
                        '--color-primary-rgb': palette.colorPrimaryRGB,
                        '--color-text': palette.colorText,
                        '--color-text-rgb': palette.colorTextRGB,
                        '--color-text-disabled': palette.colorTextDisabled,
                        '--color-text-disabled-rgb': palette.colorTextDisabledRGB,
                        '--color-secondary': palette.colorSecondary,
                        '--color-secondary-rgb': palette.colorSecondaryRGB,
                        '--color-tertiary': palette.colorTertiary,
                        '--color-tertiary-rgb': palette.colorTertiaryRGB,
                        '--color-error': palette.colorError,
                        '--color-error-rgb': palette.colorErrorRGB,
                        '--color-success': palette.colorSuccess,
                        '--color-success-rgb': palette.colorSuccessRGB,
                        '--color-warning': palette.colorWarning,
                        '--color-warning-rgb': palette.colorWarningRGB,
                        '--color-light': palette.colorLight,
                        '--color-light-rgb': palette.colorLightRGB,
                        '--color-shade': palette.colorShade,
                        '--color-shade-rgb': palette.colorShadeRGB,
                        '--color-lightGrey': palette.colorLightGrey,
                        '--color-lightGrey-rgb': palette.colorLightGreyRGB,
                        '--color-bodyBackground': palette.colorBodyBackground,
                        '--color-bodyBackground-rgb': palette.colorBodyBackgroundRGB
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '6px',
                        padding: '18px 24px',
                        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-weight 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }
                },
                defaultProps: {
                    variant: 'contained'
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            padding: '10px 15px'
                        }
                    },
                    {
                        props: { size: 'large' },
                        style: {
                            fontWeight: '800',
                            fontSize: '18px'
                        }
                    }
                ]
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px'
                    }
                },
                variants: [
                    {
                        props: { color: 'primary' },
                        style: { backgroundColor: 'var(--color-primary)' }
                    },
                    {
                        props: { color: 'secondary' },
                        style: { backgroundColor: 'var(--color-secondary)' }
                    },
                    {
                        props: { color: 'success' },
                        style: { backgroundColor: 'var(--color-success)' }
                    },
                    {
                        props: { color: 'warning' },
                        style: { backgroundColor: 'var(--color-warning)' }
                    },
                    {
                        props: { color: 'error' },
                        style: { backgroundColor: 'var(--color-error)' }
                    },
                    {
                        props: { color: 'tertiary' },
                        style: { backgroundColor: 'var(--color-tertiary)' }
                    }
                ]
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px !important',
                        padding: '20px',
                        boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px'
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '0px 16px'
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: '8px 16px 16px 16px'
                    }
                }
            },
            MuiAccordionActions: {
                styleOverrides: {
                    root: {
                        padding: '8px 36px'
                    }
                }
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '16px'
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    fullWidth: true,
                    margin: 'normal'
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px'
                    }
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px'
                    }
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        boxSizing: 'border-box'
                    },
                    message: {
                        flex: '1'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 6%), 0px 3px 14px 2px rgb(0 0 0 / 4%)',
                        flex: '1'
                    }
                }
            },
            MuiFormControl: {
                defaultProps: {
                    margin: 'normal'
                }
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        maxHeight: '250px'
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        padding: '12px 30px'
                    }
                }
            },
            MuiSnackbar: {
                defaultProps: {
                    autoHideDuration: 6000,
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap'
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '20px 24px'
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        margin: '8px 0px 0px 0px'
                    }
                }
            },
            MuiStack: {
                defaultProps: {
                    spacing: 0
                },
                variants: [
                    {
                        props: { direction: 'column' },
                        style: { maxWidth: '100%' }
                    }
                ]
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: 'var(--color-lightGrey)'
                    }
                }
            },
            MuiTypography: {
                variants: [
                    {
                        props: { color: 'success' },
                        style: { color: 'var(--color-success)' }
                    },
                    {
                        props: { color: 'warning' },
                        style: { color: 'var(--color-warning)' }
                    },
                    {
                        props: { color: 'tertiary' },
                        style: { color: 'var(--color-tertiary)' }
                    },
                    {
                        props: { color: 'light' },
                        style: { color: 'var(--color-light)' }
                    },
                    {
                        props: { color: 'lightGrey' },
                        style: { color: 'var(--color-lightGrey)' }
                    },
                    {
                        props: { variant: 'caption' },
                        style: { color: 'var(--color-text-disabled)', fontStyle: 'italic' }
                    },
                    {
                        props: { variant: 'body3' },
                        style: { fontSize: '0.75rem', lineHeight: '1.3' }
                    }
                ]
            },
            MuiStepConnector: {
                styleOverrides: {
                    root: {
                        top: '23px'
                    },
                    line: {
                        borderTopWidth: '4px'
                    }
                }
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    )
};

declare module '@mui/material/styles' {
    interface CustomPalette {
        light: PaletteColorOptions;
        shade: PaletteColorOptions;
        lightGrey: PaletteColorOptions;
        tertiary: PaletteColorOptions;
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
  }
  
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}
  
declare module '@mui/material/CircularProgress' {
    interface CircularProgressPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        light: true;
        shade: true;
        lightGrey: true;
        tertiary: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
    }
}

export default AppTheme;