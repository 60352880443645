import React, { FunctionComponent, useState } from 'react';

import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { SxProps, Theme } from '@mui/material';

import { IFormInputBase } from '@luxon/components/core/form/Form';
import { DATE_FORMATS } from '@luxon/constants';

import { Dayjs } from 'dayjs';

interface IDatePickerProps extends IFormInputBase {
    dateFormat?: string;

    minDate?: Dayjs;
    maxDate?: Dayjs;
    margin?: 'none' | 'dense' | 'normal';
    sx?: SxProps<Theme>;
}
export const DatePicker: FunctionComponent<IDatePickerProps> = (props: IDatePickerProps) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const dateFormat = props.dateFormat ?? DATE_FORMATS.DayMonthYear;

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.readOnly || props.disabled) {
            return;
        }
        setIsPickerOpen(!isPickerOpen);
    }

    return (
        <MuiDatePicker
            onChange={(value) => props.onChange(value)}
            value={props.value}
            format={dateFormat}
            label={props.label}
            slotProps={{
                textField: {
                    name: props.name,
                    disabled: props.disabled,
                    error: props.error,
                    required: props.required,
                    onClick: handleClick,
                    inputProps: {
                        readOnly: true
                    },
                    margin: props.margin,
                    sx: props.sx
                },
                inputAdornment: {
                    onClick: handleClick,
                },
                popper: {
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    altBoundary: false,
                                    fallbackPlacements: ['top', 'right', 'left'], // This is to prevent popper from being cut off at bottom or top, and just giving it the option to try to the left and right of the input as well
                                }
                            }
                        ]
                    }
                }
            }}
            open={isPickerOpen}
            closeOnSelect={true}
            onClose={() => setIsPickerOpen(false)}
            minDate={props.minDate}
            maxDate={props.maxDate}
            readOnly={props.readOnly}
        />
    );
};