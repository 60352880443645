import { FunctionComponent, HTMLAttributeAnchorTarget } from 'react';

import { Link } from 'react-router-dom';
import { TableRow, TableCell } from '@mui/material';

import { Checkbox } from '@luxon/components';

import { ITableColumn } from './TableHeaderColumn';

interface ITableDataRowProps {
    row: any;
    columns: ITableColumn[];
    rowLink?: (row: any) => string;
    rowLinkTarget?: HTMLAttributeAnchorTarget;
    rowLinkTitle?: string | ((dataRow: any) => string);
    onSelected?: () => void;
    disabled?: boolean;
    checkboxSelection?: boolean;
    checkboxChecked?: boolean;
    checkboxSelectionChanged?: () => void;
    size?: 'small' | 'medium';
}
export const TableDataRow: FunctionComponent<ITableDataRowProps> = (props: ITableDataRowProps) => {

    const getCellContent = (column: ITableColumn) => {
        if (column.element && typeof column.element === 'function') {
            return column.element(props.row);
        } else if (String(column.dataKey).indexOf('.') >= 0) {
            const keyParts = String(column.dataKey).split('.');
            let cellContent = Object.assign({}, props.row);
            for (const key of keyParts) {
                cellContent = cellContent[key];
            }
            return cellContent;
        }

        return props.row[column.dataKey];
    }

    const getRowLink = (column: ITableColumn) => {
        if (!props.rowLink || typeof props.rowLink !== 'function' || column.disableRowClick) {
            return null;
        }

        const ariaLabel = !props.rowLinkTitle ? null : typeof props.rowLinkTitle === 'function' ? props.rowLinkTitle(props.row) : props.rowLinkTitle;

        const link = props.rowLink(props.row);
        if (!link) {
            return null;
        }
        else if (link.startsWith('http')) { // External Link
            return (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    title={ariaLabel}
                    aria-label={ariaLabel}
                    href={link}
                    target={props.rowLinkTarget}
                    rel={props.rowLinkTarget === '_blank' ? 'noreferrer noopener' : null}
                    className='absolute-fill pointer' />
            )
        }

        return (
            <Link
                to={props.rowLink(props.row)}
                target={props.rowLinkTarget}
                rel={props.rowLinkTarget === '_blank' ? 'noreferrer noopener' : null}
                className='absolute-fill pointer'
                aria-label={ariaLabel}
                title={ariaLabel}
            />
        )
    }

    return (
        <TableRow hover={true}>
            {
                props.checkboxSelection && (
                    <TableCell>
                        <Checkbox
                            value={props.checkboxChecked}
                            onChange={props.checkboxSelectionChanged}
                            disabled={props.disabled}
                            size={props.size}
                        />
                    </TableCell>
                )
            }
            {
                props.columns.map(column => (
                    <TableCell
                        key={String(column.dataKey) ?? column.title}
                        onClick={column.disableRowClick || props.disabled ? null : props.onSelected}
                        sx={{
                            position: 'relative',
                            whiteSpace: column.allowWrapping ? 'normal' : 'nowrap',
                            maxWidth: column.maxWidth,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {getCellContent(column)}
                        {getRowLink(column)}
                    </TableCell>
                ))
            }
        </TableRow>
    )
}