import React, { FunctionComponent } from 'react';

import { Stack } from '@mui/material';

interface IIconTextProps {
    icon: React.ReactNode;
    iconSuffix?: React.ReactNode;
    children: React.ReactNode;
}
export const IconText: FunctionComponent<IIconTextProps> = (props: IIconTextProps) => {

    const {
        icon,
        iconSuffix,
        children
    } = props;

    return (
        <Stack display='flex' flexDirection='row' alignItems='center' columnGap={1}>
            {icon}
            {children}
            {iconSuffix}
        </Stack>
    )
};