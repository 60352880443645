import { FunctionComponent } from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import { useUserContext, useClientContext } from '@luxon/hooks';
import { calculateHomePage } from '@luxon/utils';



interface IRedirectLoggedInUserProps {}
const RedirectLoggedInUser: FunctionComponent<IRedirectLoggedInUserProps> = (props: IRedirectLoggedInUserProps) => {
    const [searchParams] = useSearchParams();

    const { user } = useUserContext();
    const { client } = useClientContext();

    if (searchParams.get('r')) {
        return <Navigate to={searchParams.get('r')} />;
    } 
    
    const homePageUrl = calculateHomePage(user, client);
    return <Navigate to={homePageUrl} />
};

export default RedirectLoggedInUser;