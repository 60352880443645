export function searchItems<T>(searchText: string, itemSearchKeys: (keyof T)[], items: T[]): T[] {
  if (!searchText) {
    return items;
  }

  const searchTextParts = searchText.split(' ').map(x => x.trim().toLowerCase()).filter(x => x.length > 0);

  return items
    .filter(x => {
      let hasMatch = false;
      for (const key of itemSearchKeys) {
        const searchPropValueParts = `${x[key]}`.toLowerCase().split(' ').map(x => x.trim().toLowerCase()).filter(x => x.length > 0);
        hasMatch = searchPropValueParts.filter(y => searchTextParts.filter(z => y.includes(z)).length > 0).length > 0;
        if (hasMatch) {
          break;
        }
      }

      return hasMatch;
    });
}