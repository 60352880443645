import React, { FunctionComponent, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { LazyLoader, FeatureGuard } from '@luxon/components';
import RouterErrorPage from '@luxon/pages/error/RouterErrorPage';
import { FeatureFlag } from '@luxon/models';
import { getRouteName } from '@luxon/utils';
import { ROUTES } from './constants';

const HomePage = lazy(() => import('./pages/home/HomePage'));
const HomeV2Page = lazy(() => import('./pages/home/HomeV2Page'));
const DemoAccessPage = lazy(() => import('./pages/demo-access/DemoAccessPage'));

const LoginPage = lazy(() => import('./pages/login/LoginPage'));
const TwoFactorAuthPage = lazy(() => import('./pages/two-factor-auth/TwoFactorAuthPage'));
const ResetPasswordPage = lazy(() => import('./pages/reset-password/ResetPasswordPage'));
const SignOutPage = lazy(() => import('./pages/sign-out/SignOutPage'));
const UserSetupPage = lazy(() => import('./pages/user-setup/UserSetupPage'));
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const UserPage = lazy(() => import('./pages/user/UserPage'));

const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));

const ClientsPage = lazy(() => import('./pages/clients/ClientsPage'));
const ClientPage = lazy(() => import('./pages/client/ClientPage'));
const ClientOnboardingPage = lazy(() => import('./pages/client-onboarding/ClientOnboardingPage'));

const AgenciesListPage = lazy(() => import('./pages/agencies/AgenciesListPage'));
const AgencySiteOwnersListPage = lazy(() => import('./pages/agencies/AgencySiteOwnersListPage'));
const ConnectedAgenciesPage = lazy(() => import('./pages/agencies/ConnectedAgenciesPage'));
const AgencySiteOwnerSitesListPage = lazy(() => import('./pages/agencies/AgencySiteOwnerSitesListPage'));
const AgencySiteOwnerSiteAnalyticsPage = lazy(() => import('./pages/agencies/AgencySiteOwnerSiteAnalyticsPage'));

const AssetsPage = lazy(() => import('./pages/assets/AssetsPage'));
const AssetPage = lazy(() => import('./pages/asset/AssetPage'));
const AssetsMapPage = lazy(() => import('./pages/assets-map/AssetsMapPage'));
const AssetIPAllocationsPage = lazy(() => import('./pages/assets-ip-allocations/AssetIPAllocationsPage'));
const AssetsSnapshotsPage = lazy(() => import('./pages/assets-snapshots/AssetsSnapshotsPage'));
const AssetsPlannerPage = lazy(() => import('./pages/assets-planner/AssetsPlannerPage'));

const BillboardsPage = lazy(() => import('./pages/billboards/BillboardsPage'));
const BillboardPage = lazy(() => import('./pages/billboard/BillboardPage'));
const BillboardsMapPage = lazy(() => import('./pages/billboards-map/BillboardsMapPage'));

const ClientInvoicesPage = lazy(() => import('./pages/client-invoices/ClientInvoicesPage'));

const AnalyticsV2Page = lazy(() => import('./pages/analytics/AnalyticsV2Page'));
const BillboardAnalyticsPage = lazy(() => import('./pages/billboard-analytics/BillboardAnalyticsPage'));

const SupportTicketsPage = lazy(() => import('./pages/support-tickets/SupportTicketsPage'));
const SupportTicketPage = lazy(() => import('./pages/support-ticket/SupportTicketPage'));

const ClientCampaignsPage = lazy(() => import('./pages/client-campaigns/ClientCampaignsPage'));
const ClientCampaignPage = lazy(() => import('./pages/client-campaign/ClientCampaignPage'));

const QuotesPage = lazy(() => import('./pages/quotes/QuotesPage'));
const QuotePage = lazy(() => import('./pages/quote/QuotePage'));
const QuoteActionPage = lazy(() => import('./pages/quote-action/QuoteActionPage'));

const ClientInventoryRequestsPage = lazy(() => import('./pages/client-inventory-requests/ClientInventoryRequestsPage'));
const InventoryRequestSecureUploadPage = lazy(() => import('./pages/inventory-request-secure-upload/InventoryRequestSecureUploadPage'));

const XeroSetupPage = lazy(() => import('./pages/xero-setup/XeroSetupPage'));

const AppRouter: FunctionComponent = () => {

    const routes = createBrowserRouter([
        {
            element: (
                <LazyLoader title='Home'>
                    <FeatureGuard
                        fallbackComponent={<HomePage />}
                        featureName={FeatureFlag.HOME_PAGE_V2_ENABLED}
                    >
                        <HomeV2Page />
                    </FeatureGuard>
                </LazyLoader>
            ),
            index: true,
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.DemoAccess),
            element: (
                <LazyLoader title='Demo Access'>
                    <DemoAccessPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.Login),
            element: (
                <LazyLoader title='Login'>
                    <LoginPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.Dashboard),
            element: (
                <LazyLoader noTitle protected allowOnboardingClients allowSuspendedClients>
                    <DashboardPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />,
            children: [
//#region SYSTEM USERS
                {
                    path: getRouteName(ROUTES.Clients, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Clients' isAllowed={(user) => user.isSystemsUser}>
                            <ClientsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageClient, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <ClientPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ClientUsers, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Client Users' isAllowed={(user) => user.isSystemsUser}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageClientUser, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ClientInvoices, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Client Invoices' isAllowed={(user) => user.isSystemsUser}>
                            <ClientInvoicesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Agencies, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Agencies' isAllowed={(user) => user.isSystemsUser}>
                            <AgenciesListPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageAgency, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <ClientPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.AgencyUsers, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Agency Users' isAllowed={(user) => user.isSystemsUser}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageAgencyUser, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.AgencyInvoices, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Agency Invoices' isAllowed={(user) => user.isSystemsUser}>
                            <ClientInvoicesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.SystemUsers, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='System Users' allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageSystemUser, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle allowedRoles={['SystemsOwner', 'SystemsAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.CameraAssets, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Assets' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.CameraAssetsMap, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Assets Map' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsMapPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.CameraAssetsIpAllocations, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Asset IP Allocations' isAllowed={(user) => user.isSystemsUser}>
                            <AssetIPAllocationsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.CameraAssetsSnapshots, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Asset Snapshots' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsSnapshotsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.CameraAssetsPlanner, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Asset Planner' isAllowed={(user) => user.isSystemsUser}>
                            <AssetsPlannerPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageCameraAsset, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <AssetPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Billboards, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Billboards' isAllowed={(user) => user.isSystemsUser}>
                            <BillboardsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.BillboardsMap, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Billboards Map' isAllowed={(user) => user.isSystemsUser}>
                            <BillboardsMapPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageBillboard, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <BillboardPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Quotes, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Quotes' isAllowed={(user) => user.isSystemsUser}>
                            <QuotesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageQuote, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => user.isSystemsUser}>
                            <QuotePage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ClientInventoryRequests, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Client Inventory Requests' allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Sales']}>
                            <ClientInventoryRequestsPage />
                        </LazyLoader>
                    )
                },
//#endregion SYSTEM USERS


//#region CLIENT USERS
                {
                    path: getRouteName(ROUTES.ClientOnboarding, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Onboarding' allowOnboardingClients isAllowed={(user) => !user.isSystemsUser}>
                            <ClientOnboardingPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Users, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Users' allowedRoles={['ClientOwner', 'ClientAdmin', 'AgencyOwner', 'AgencyAdmin']}>
                            <UsersPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageUser, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle allowedRoles={['ClientOwner', 'ClientAdmin', 'AgencyOwner', 'AgencyAdmin']}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Analytics, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Inventory' isAllowed={(user) => !user.isSystemsUser}>
                            <AnalyticsV2Page />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.SiteOwners, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Site Owners' isAllowed={(_, client) => client.clientType === 'Agency'}>
                            <AgencySiteOwnersListPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.SiteOwnerSites, ROUTES.Dashboard),
                    element: (
                        <LazyLoader isAllowed={(_, client) => client.clientType === 'Agency'}>
                            <AgencySiteOwnerSitesListPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.SiteOwnerSiteAnalytics, ROUTES.Dashboard),
                    element: (
                        <LazyLoader isAllowed={(_, client) => client.clientType === 'Agency'}>
                            <AgencySiteOwnerSiteAnalyticsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.BillboardAnalytics, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isSystemsUser}>
                            <BillboardAnalyticsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Account, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isSystemsUser} allowedSuspendedReasons={['NoPayment']}>
                            <ClientPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ConnectedAgencies, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Connected Agencies' isAllowed={(user) => !user.isSystemsUser}>
                            <ConnectedAgenciesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Invoices, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Invoices' isAllowed={(user) => !user.isSystemsUser} allowedSuspendedReasons={['NoPayment']}>
                            <ClientInvoicesPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.Campaigns, ROUTES.Dashboard),
                    element: (
                        <LazyLoader title='Campaigns' isAllowed={(user) => !user.isSystemsUser}>
                            <ClientCampaignsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageCampaign, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isSystemsUser}>
                            <ClientCampaignPage />
                        </LazyLoader>
                    )
                },
//#endregion CLIENT USERS
                

//#region SHARED ROUTES
                {
                    path: getRouteName(ROUTES.Profile, ROUTES.Dashboard),
                    element: (
                        <LazyLoader noTitle isAllowed={(user) => !user.isAssumedSystemUser}>
                            <UserPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.SupportTickets, ROUTES.Dashboard),
                    element: (
                        <LazyLoader
                            title='Support'
                            allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Support', 'ClientOwner', 'ClientAdmin', 'ClientViewOnly', 'AgencyOwner', 'AgencyAdmin', 'AgencyViewOnly']}
                            allowedSuspendedReasons={['NoPayment']}
                        >
                            <SupportTicketsPage />
                        </LazyLoader>
                    )
                },
                {
                    path: getRouteName(ROUTES.ManageSupportTicket, ROUTES.Dashboard),
                    element: (
                        <LazyLoader
                            noTitle
                            allowedRoles={['SystemsOwner', 'SystemsAdmin', 'Support', 'ClientOwner', 'ClientAdmin', 'ClientViewOnly', 'AgencyOwner', 'AgencyAdmin', 'AgencyViewOnly']}
                            allowedSuspendedReasons={['NoPayment']}
                        >
                            <SupportTicketPage />
                        </LazyLoader>
                    )
                }
//#endregion SHARED ROUTES
            ]
        },
        {
            path: getRouteName(ROUTES.SignOut),
            element: (
                <LazyLoader title='Sign Out'>
                    <SignOutPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.UserSetup),
            element: (
                <LazyLoader title='Setup Account'>
                    <UserSetupPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.TwoFactorAuthSetup),
            element: (
                <LazyLoader title='Setup 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.TwoFactorAuthVerify),
            element: (
                <LazyLoader title='Verify 2FA'>
                    <TwoFactorAuthPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.ResetPassword),
            element: (
                <LazyLoader title='Reset Password'>
                    <ResetPasswordPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.XeroSetupStart),
            element: (
                <LazyLoader title='Link Xero Account' allowedRoles={['SystemsOwner']}>
                    <XeroSetupPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.XeroSetupComplete),
            element: (
                <LazyLoader title='Xero Account Linked' allowedRoles={['SystemsOwner']}>
                    <XeroSetupPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.ActionQuote),
            element: (
                <LazyLoader noTitle>
                    <QuoteActionPage />
                </LazyLoader>
            ),
            errorElement: <RouterErrorPage />
        },
        {
            path: getRouteName(ROUTES.UploadInventoryRequest),
            element: (
                <LazyLoader title='Secure Document Upload'>
                    <InventoryRequestSecureUploadPage />
                </LazyLoader>
            )
        },
        {
            path: '*',
            element: (
                <Navigate to='/' replace />
            ),
            errorElement: <RouterErrorPage />
        }
    ]);

    return (
        <RouterProvider router={routes} />
    );
};

export default AppRouter;