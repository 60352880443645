import { IGeoPoint } from "@luxon/interfaces";

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const QUERY_CLIENT_STALE_TIME = 1000 * 60 * 5; // 5 Minutes

export const DATE_FORMATS = {
  /**
   * `D MMM YYYY HH:mm:ss`
   */
  FullDateTime: 'D MMM YYYY HH:mm:ss',

  /**
   * `D MMM YYYY`
   */
  DayMonthYear: 'D MMM YYYY',

  /**
   * `D MMM`
   */
  DayMonth: 'D MMM',

  /**
   * `D`
   */
  Day: 'D',

  /**
   * `ddd`
   */
  DayOfWeek: 'ddd',

  /**
   * `HH:mm`
   */
  HourMinute: 'HH:mm',

  /**
   * `D MMM HH:mm`
   */
  DayMonthHour: 'D MMM HH:mm'
};

const DASHBOARD_ROOT = '/dashboard';
const AGENCIES_ROOT = `${DASHBOARD_ROOT}/agencies`;
const CLIENTS_ROOT = `${DASHBOARD_ROOT}/clients`;
const SYSTEM_USERS_ROOT = `${DASHBOARD_ROOT}/system-users`;
const CAMERA_ASSETS_ROOT = `${DASHBOARD_ROOT}/camera-assets`;
const BILLBOARDS_ROOT = `${DASHBOARD_ROOT}/billboards`;
const QUOTES_ROOT = `${DASHBOARD_ROOT}/quotes`;
const USERS_ROOT = `${DASHBOARD_ROOT}/users`;
const ANALYTICS_ROOT = `${DASHBOARD_ROOT}/analytics`;
const CAMPAIGNS_ROOT = `${DASHBOARD_ROOT}/campaigns`;
const SUPPORT_ROOT = `${DASHBOARD_ROOT}/support`;
const SITE_OWNERS_ROOT = `${DASHBOARD_ROOT}/site-owners`;

export const ROUTES = {
  Root: '/',
  Login: '/login',
  DemoAccess: '/demo-access',
  SignOut: '/sign-out/:userId',
  UserSetup: '/user-setup',
  TwoFactorAuthSetup: '/2fa/setup',
  TwoFactorAuthVerify: '/2fa/verify',
  ResetPassword: '/reset-password',
  XeroSetupStart: '/xero-setup',
  XeroSetupComplete: '/xero-setup/auth-response',
  ActionQuote: '/quotes/:quoteId',
  UploadInventoryRequest: '/inventory-request/secure-upload/:clientInventoryRequestId',

  Dashboard: DASHBOARD_ROOT,

  Agencies: AGENCIES_ROOT,
  ManageAgency: `${AGENCIES_ROOT}/:agencyId`,
  AgencyUsers: `${AGENCIES_ROOT}/:agencyId/users`,
  ManageAgencyUser: `${AGENCIES_ROOT}/:agencyId/users/:userId`,
  AgencyInvoices: `${AGENCIES_ROOT}/:agencyId/invoices`,

  SiteOwners: SITE_OWNERS_ROOT, 
  SiteOwnerSites: `${SITE_OWNERS_ROOT}/:clientId`, 
  SiteOwnerSiteAnalytics: `${SITE_OWNERS_ROOT}/:clientId/sites/:billboardId`, 

  Clients: CLIENTS_ROOT,
  ManageClient: `${CLIENTS_ROOT}/:clientId`,
  ClientUsers: `${CLIENTS_ROOT}/:clientId/users`,
  ManageClientUser: `${CLIENTS_ROOT}/:clientId/users/:userId`,
  ClientInvoices: `${CLIENTS_ROOT}/:clientId/invoices`,

  SystemUsers: SYSTEM_USERS_ROOT,
  ManageSystemUser: `${SYSTEM_USERS_ROOT}/:userId`,

  CameraAssets: CAMERA_ASSETS_ROOT,
  ManageCameraAsset: `${CAMERA_ASSETS_ROOT}/:assetId`,
  CameraAssetsMap: `${CAMERA_ASSETS_ROOT}/map`,
  CameraAssetsIpAllocations: `${CAMERA_ASSETS_ROOT}/ip-allocations`,
  CameraAssetsSnapshots: `${CAMERA_ASSETS_ROOT}/snapshots`,
  CameraAssetsPlanner: `${CAMERA_ASSETS_ROOT}/planner`,

  Billboards: BILLBOARDS_ROOT,
  ManageBillboard: `${BILLBOARDS_ROOT}/:billboardId`,
  BillboardsMap: `${BILLBOARDS_ROOT}/map`,

  Quotes: QUOTES_ROOT,
  ManageQuote: `${QUOTES_ROOT}/:quoteId`,

  ClientInventoryRequests: `${DASHBOARD_ROOT}/client-inventory-requests`,

  ClientOnboarding: `${DASHBOARD_ROOT}/onboarding`,

  Users: USERS_ROOT,
  ManageUser: `${USERS_ROOT}/:userId`,

  Analytics: ANALYTICS_ROOT,
  BillboardAnalytics: `${ANALYTICS_ROOT}/:billboardId`,

  Account: `${DASHBOARD_ROOT}/account`,

  Invoices: `${DASHBOARD_ROOT}/invoices`,

  ConnectedAgencies: `${DASHBOARD_ROOT}/connected-agencies`,

  Campaigns: CAMPAIGNS_ROOT,
  ManageCampaign: `${CAMPAIGNS_ROOT}/:campaignId`,

  Profile: `${DASHBOARD_ROOT}/profile`,

  SupportTickets: SUPPORT_ROOT,
  ManageSupportTicket: `${SUPPORT_ROOT}/:supportTicketId`
}

// Defaults to the center of Midrand, Gauteng, ZA
export const DEFAULT_MAP_CENTER: IGeoPoint = {
  latitude: -26.19112715496492,
  longitude: 28.02767024340821,
};
