import { FunctionComponent } from 'react';

import { LineChart as RechartsLineChart, Line as RechartsLine } from 'recharts';

import { IGenericChartProps } from './Chart';

const LineChart: FunctionComponent<IGenericChartProps> = (props: IGenericChartProps) => {
    return (
        <RechartsLineChart
            data={props.data}
            width={props.width}
            height={props.height}
            className='chart'
            margin={{ left: -20 }}
        >
            {props.children}
            {
                props.dataKeys
                    .filter(x => props.disabledKeys.indexOf(x.dataKey) < 0)
                    .map(dataKey => (
                        <RechartsLine
                            key={dataKey.dataKey}
                            type="monotone"
                            name={dataKey.legendName}
                            dataKey={dataKey.dataKey}
                            stroke={`var(--color-${dataKey.color})`}
                            dot={false}
                            activeDot={{ r: 3 }}
                            id={dataKey.dataKey}
                            animationDuration={500}
                            animationBegin={0}
                        />
                    ))
            }
        </RechartsLineChart>
    )
};

export default LineChart;