import { useState } from "react"

export function useBinaryToggle<T>(defaultValue?: boolean) {
  const [isOpen, setIsOpen] = useState(defaultValue ?? false);
  const [data, setData] = useState<T | undefined>();

  const toggle = (data?: T) => {
    setIsOpen(!isOpen);
    setData(data);
  }

  return {
    isOpen,
    setIsOpen,
    toggle,
    data
  }
}