import { useQuery, useMutation, useQueryClient } from "react-query";

import { IClient, ICreateClientRequest, IDefaultResponseMessage, IGetClientsRequest, IPaginationWrapper, ISubscriptionFee, IUpdateClientRequest, TStatus, TSuspendedReason } from '@luxon/interfaces';
import { HttpClientError, QueryKeys, getQuerySettings, IQuerySettings } from '@luxon/models';
import { useHttpClient, useSnackbar, useUserContext } from '@luxon/hooks';
import { IAgencyAccessActionRequestRequest, IAgencyClientAccess, IAgencyClientBillboardActivationActionRequestRequest, IAgencyClientBillboardActivationRequest } from "@luxon/models/agency";

enum ClientQueryKey {
  SubscriptionFee = 'subscription-fee',
  List = 'List',
  AgencyAccessRequests = 'AgencyAccessRequests',
  AgencyBillboardActivationRequests = 'AgencyBillboardActivationRequests',
}

interface IRequest<T> {
  clientId: string;
  request: T;
}

const BASE_URL = '/v1/clients';

export const useGetClient = (clientId: string, includeDisabledBillboards?: boolean, settings?: IQuerySettings<IClient>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IClient, HttpClientError>([QueryKeys.Clients, clientId, includeDisabledBillboards ?? false], () => {
        return GET<IClient>(`${BASE_URL}/${clientId}`, { includeDisabledBillboards }, {
          autoShowErrorMessages: querySettings.autoShowErrorMessages && !querySettings.refetchInterval,
          autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
        });
    }, {
      ...querySettings,
      enabled: querySettings.enabled && !!clientId,
    });
}

export const useGetClients = (request: IGetClientsRequest, settings?: IQuerySettings<IPaginationWrapper<IClient>>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IPaginationWrapper<IClient>, HttpClientError>([QueryKeys.Clients, ClientQueryKey.List, request], () => {
      return GET<IPaginationWrapper<IClient>>(BASE_URL, request);
  }, {
    ...querySettings,
  });
}

export const useGetClientSubscriptionFee = (clientId: string, settings?: IQuerySettings<ISubscriptionFee>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<ISubscriptionFee, HttpClientError>([QueryKeys.Clients, ClientQueryKey.SubscriptionFee, clientId], () => {
        return GET<ISubscriptionFee>(`${BASE_URL}/${clientId}/subscription-fee`);
    }, {
      ...querySettings,
      enabled: querySettings.enabled && !!clientId
    });
}

export const useGetClientSubscriptionAgreement = (settings?: IQuerySettings<IDefaultResponseMessage, { clientId: string }>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const getClientSubscriptionAgreement = ({ clientId }: { clientId: string }) => {
    return GET<IDefaultResponseMessage>(`${BASE_URL}/${clientId}/subscription-agreement`);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, { clientId: string }>(getClientSubscriptionAgreement, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useUpdateSubscriptionAgreement = (settings?: IQuerySettings<IClient, { clientId: string, file: File }>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateSubscriptionAgreement = ({ clientId, file }: { clientId: string, file: File }) => {
    return POST<IClient>(`${BASE_URL}/${clientId}/subscription-agreement`, null, file);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, { clientId: string, file: File }>(updateSubscriptionAgreement, {
    onSuccess: (response, input) => {
      showSnackbar('Subscription agreement successfully updated.');
      queryClient.setQueryData([QueryKeys.Clients, input.clientId, false], response);
      queryClient.setQueryData([QueryKeys.Clients, input.clientId, true], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useCreateClient = (settings?: IQuerySettings<IClient, ICreateClientRequest>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createClient = (request: ICreateClientRequest) => {
    return POST<IClient>(BASE_URL, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, ICreateClientRequest>(createClient, {
    onSuccess: (response, input) => {
      showSnackbar('Client successfully created.');
      queryClient.setQueryData([QueryKeys.Clients, response.id, false], response);
      queryClient.setQueryData([QueryKeys.Clients, response.id, true], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

interface UpdateClientRequest {
  clientId: string;
  request: IUpdateClientRequest;
  finishOnboarding?: boolean;
  newLogo?: File;
}
export const useUpdateClient = (settings?: IQuerySettings<IClient, UpdateClientRequest>) => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const updateClient = ({ clientId, request, newLogo, finishOnboarding }: UpdateClientRequest) => {
    return POST<IClient>(buildUrl(`${BASE_URL}/${clientId}`, { finishOnboarding: finishOnboarding ?? false }), request, newLogo, {
      bodyAsForm: true
    });
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, UpdateClientRequest>(updateClient, {
    onSuccess: (response, input) => {
      showSnackbar(`${user.isSystemsUser ? 'Client' : 'Account'} successfully updated.`);
      queryClient.setQueryData([QueryKeys.Clients, response.id, false], response);
      queryClient.setQueryData([QueryKeys.Clients, response.id, true], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

interface UpdateClientStatusRequest {
  clientId: string;
  status: TStatus;
  suspendedReason: TSuspendedReason;
}
export const useUpdateClientStatus = (settings?: IQuerySettings<IClient, UpdateClientStatusRequest>) => {
  const { PUT, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateClientStatus = ({ clientId, status, suspendedReason }: UpdateClientStatusRequest) => {
    return PUT<IClient>(buildUrl(`${BASE_URL}/${clientId}/status`, { status, suspendedReason }));
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IClient, HttpClientError, UpdateClientStatusRequest>(updateClientStatus, {
    onSuccess: (response, input) => {
      showSnackbar(`Client successfully ${response.status === 'Suspended' ? 'suspended' : 'activated'}`);
      queryClient.setQueryData([QueryKeys.Clients, response.id, false], response);
      queryClient.setQueryData([QueryKeys.Clients, response.id, true], response);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useGetClientAgencyAccessRequests = (clientId: string, settings?: IQuerySettings<IAgencyClientAccess[]>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IAgencyClientAccess[], HttpClientError>([QueryKeys.Clients, ClientQueryKey.AgencyAccessRequests, clientId], () => {
        return GET<IAgencyClientAccess[]>(`${BASE_URL}/${clientId}/agency-access-requests`);
    }, {
      ...querySettings,
      enabled: querySettings.enabled && !!clientId
    });
}

export const useGetClientAgencyBillboardActivationRequests = (clientId: string, settings?: IQuerySettings<IAgencyClientBillboardActivationRequest[]>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IAgencyClientBillboardActivationRequest[], HttpClientError>([QueryKeys.Clients, ClientQueryKey.AgencyBillboardActivationRequests, clientId], () => {
        return GET<IAgencyClientBillboardActivationRequest[]>(`${BASE_URL}/${clientId}/agency-billboard-activation-requests`);
    }, {
      ...querySettings,
      enabled: querySettings.enabled && !!clientId
    });
}

export const useActionClientAgencyAccessRequestMutation = (settings?: IQuerySettings<IAgencyClientAccess, IRequest<IAgencyAccessActionRequestRequest>>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const actionClientAgencyAccessRequest = ({ clientId, request }: IRequest<IAgencyAccessActionRequestRequest>) => {
    return POST<IAgencyClientAccess>(`${BASE_URL}/${clientId}/agency-access-requests/action`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IAgencyClientAccess, HttpClientError, IRequest<IAgencyAccessActionRequestRequest>>(actionClientAgencyAccessRequest, {
    onSuccess: (response, input) => {
      showSnackbar(`Agency Connection successfully actioned`);
      queryClient.invalidateQueries([QueryKeys.Clients, ClientQueryKey.AgencyAccessRequests, input.clientId]);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}

export const useActionClientAgencyBillboardActivationRequestMutation = (settings?: IQuerySettings<IDefaultResponseMessage, IRequest<IAgencyClientBillboardActivationActionRequestRequest>>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const actionClientAgencyBillboardActivationRequest = ({ clientId, request }: IRequest<IAgencyClientBillboardActivationActionRequestRequest>) => {
    return POST<IDefaultResponseMessage>(`${BASE_URL}/${clientId}/agency-billboard-activation-requests/action`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, IRequest<IAgencyClientBillboardActivationActionRequestRequest>>(actionClientAgencyBillboardActivationRequest, {
    onSuccess: (response, input) => {
      showSnackbar(`Site activation request successfully actioned`);
      queryClient.invalidateQueries([QueryKeys.Clients, ClientQueryKey.AgencyBillboardActivationRequests, input.clientId]);
      queryClient.invalidateQueries([QueryKeys.Clients, input.clientId, false]);
      queryClient.invalidateQueries([QueryKeys.Clients, input.clientId, true]);

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
  });
}