import React from 'react';

import {
    AppSettingsContext,
    HttpClientContext,
    SnackbarContext,
    ConfirmContext,
    ScreenSizeContext,
    ClientContext,
    UserContext,
    LocalStorageContext
} from '@luxon/contexts';

export * from './useLoadingManager';
export * from './usePageTitle';
export * from './useThemePalette';
export * from './useBinaryToggle';
export * from './useRouteData';

export const useAppSettings = () => React.useContext(AppSettingsContext);
export const useHttpClient = () => React.useContext(HttpClientContext);
export const useSnackbar = () => React.useContext(SnackbarContext);
export const useConfirm = () => React.useContext(ConfirmContext);
export const useScreenSize = () => React.useContext(ScreenSizeContext);
export const useClientContext = () => React.useContext(ClientContext);
export const useUserContext = () => React.useContext(UserContext);
export const useLocalStorageContext = () => React.useContext(LocalStorageContext);