import { FunctionComponent, useState, useEffect } from 'react';

import { Menu, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { IGeoPoint } from '@luxon/interfaces';
import { useThemePalette } from '@luxon/hooks';

interface IGeoPolygonProps {
    map: google.maps.Map;
    points: IGeoPoint[];
    editable?: boolean;
    pointsChanged?: (points: IGeoPoint[]) => void;
}
export const GeoPolygon: FunctionComponent<IGeoPolygonProps> = (props: IGeoPolygonProps) => {
    const themePalette = useThemePalette();
    
    const [polygon, setPolygon] = useState<google.maps.Polygon>();
    const [polygonListeners, setPolygonListeners] = useState<google.maps.MapsEventListener[]>([]);
    const [polyPointContextMenu, setPolyPointContextMenu] = useState<{ point: IGeoPoint, elRef: HTMLElement }>();
    const polyPointContextMenuOpen = Boolean(polyPointContextMenu?.elRef);

    const polygonPathChanged = () => {
        const newPoints: IGeoPoint[] = [];
        polygon.getPath().forEach(x => {
            newPoints.push({
                latitude: x.lat(),
                longitude: x.lng()
            });
        });
        if (props.pointsChanged) {
            props.pointsChanged(newPoints);
        }
    };

    const removePolygonPoint = () => {
        if (!polyPointContextMenu || !polyPointContextMenu.point) {
            setPolyPointContextMenu(null);
            return;
        }

        const polygonPoints: IGeoPoint[] = [];
        polygon.getPath().forEach((x, index) => {
            const point: IGeoPoint = {
                latitude: x.lat(),
                longitude: x.lng()
            };
            if (point.latitude !== polyPointContextMenu.point.latitude || point.longitude !== polyPointContextMenu.point.longitude) {
                polygonPoints.push(point);
            }
        });
        setPolyPointContextMenu(null);
        if (props.pointsChanged) {
            props.pointsChanged(polygonPoints);
        }
    };

    useEffect(() => {
        if (!polygon) {
            setPolygon(new google.maps.Polygon({
                paths: [],
                strokeColor: themePalette.secondary,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: themePalette.primary,
                fillOpacity: 0.3,
                clickable: false,
                draggable: false
            }));
        }

        return () => {
            if (polygon) {
                polygon.setMap(null);
            }
        }
    }, [polygon, themePalette]);

    useEffect(() => {
        if (!polygon) {
            return;
        }

        polygon.setMap(props.map);
        polygon.setEditable(props.editable ?? false);

        polygonListeners.forEach(x => x.remove());
        polygon.setPath(props.points.map(x => ({
            lat: x.latitude,
            lng: x.longitude
        })));
        setPolygonListeners([
            google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
                polygonPathChanged();
            }),
            google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
                polygonPathChanged();
            }),
            google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                polygonPathChanged();
            }),
            polygon.addListener('contextmenu', (e: google.maps.PolyMouseEvent) => {
                setPolyPointContextMenu({
                    elRef: e.domEvent.target as HTMLElement,
                    point: {
                        latitude: e.latLng.lat(),
                        longitude: e.latLng.lng()
                    }
                });
            })
        ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polygon, props.points, props.editable]);

    return (
        <Menu
            open={polyPointContextMenuOpen}
            anchorEl={polyPointContextMenu?.elRef}
            onClose={() => setPolyPointContextMenu(null)}
        >
            <MenuItem onClick={removePolygonPoint}>
                <ListItemIcon>
                    <Delete />
                </ListItemIcon>
                <ListItemText>Remove</ListItemText>
            </MenuItem>
        </Menu>
    );
};