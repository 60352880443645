import { FunctionComponent } from 'react';
import './styles/InlineBadge.scss';

import { Stack } from '@mui/material';

import { TColor } from '@luxon/interfaces';
import { classNames } from '@luxon/utils';

interface IInlineBadgeProps {
  counter?: number;
  color?: TColor;
}
export const InlineBadge: FunctionComponent<IInlineBadgeProps> = (props: IInlineBadgeProps) => {

  const {
    counter,
    color
  } = props;

  if (!counter) {
    return null;
  }

  return (
    <Stack className={classNames('inline-badge', color ?? 'primary')} justifyContent='center' alignItems='center'>
      {counter}
    </Stack>
  )
}
