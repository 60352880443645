import React, { FunctionComponent, useState } from 'react';

import { TimePicker as MuiTimePicker,  } from '@mui/x-date-pickers';
import { SxProps, Theme } from '@mui/material';

import { IFormInputBase } from '@luxon/components/core/form/Form';
import { DATE_FORMATS } from '@luxon/constants';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { Dayjs } from 'dayjs';

interface ITimePickerProps extends IFormInputBase {
    timeFormat?: string;

    minTime?: Dayjs;
    maxTime?: Dayjs;
    margin?: 'none' | 'dense' | 'normal';
    sx?: SxProps<Theme>;
    views?: ('hours' | 'minutes' | 'seconds')[];
}
export const TimePicker: FunctionComponent<ITimePickerProps> = (props: ITimePickerProps) => {

    const {
        timeFormat,
        readOnly,
        disabled,
        name,
        error,
        required,
        value,
        margin,
        sx,
        onChange,
        label,
        minTime,
        maxTime,
        views
    } = props;

    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const dateFormat = timeFormat ?? DATE_FORMATS.HourMinute;

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (readOnly || disabled) {
            return;
        }
        setIsPickerOpen(!isPickerOpen);
    }

    return (
        <MuiTimePicker
            onChange={(value) => onChange(value)}
            value={value}
            format={dateFormat}
            label={label}
            disabled={disabled}
            slotProps={{
                textField: {
                    name,
                    disabled,
                    error,
                    required,
                    onClick: handleClick,
                    inputProps: {
                        readOnly: true
                    },
                    margin,
                    sx,
                },
                inputAdornment: {
                    onClick: handleClick
                }
            }}
            open={isPickerOpen}
            closeOnSelect={true}
            onClose={() => setIsPickerOpen(false)}
            minTime={minTime}
            maxTime={maxTime}
            readOnly={readOnly}
            views={views}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
        />
    )
};