import { FunctionComponent } from 'react';

import { TableBody, TableRow, TableCell, Skeleton } from '@mui/material';

interface ITableLoaderProps {
    columnCount: number;
    rows?: number;
}
export const TableLoader: FunctionComponent<ITableLoaderProps> = (props: ITableLoaderProps) => {

    return (
        <TableBody>
            <TableRow>
                {
                    Array.from(Array(props.columnCount).keys()).map(columnIndex => (
                        <TableCell key={columnIndex}>
                            {
                                Array.from(Array(props.rows ?? 3).keys()).map(rowIndex => (
                                    <Skeleton key={rowIndex} animation='wave' style={{height: '30px', marginBottom: '20px'}} />
                                ))
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableBody>
    )
};