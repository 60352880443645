import { useState, useEffect, HTMLAttributeAnchorTarget, useMemo } from 'react';

import { TableContainer, Card, CardContent, Table as MuiTable, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell } from '@mui/material';

import { Checkbox } from '@luxon/components';

import { TableHeaderColumn, ITableColumn } from './TableHeaderColumn';
import { TableDataRow } from './TableDataRow';
import { TableLoader } from './TableLoader';
import { TableNoDataRow } from './TableNoDataRow';

import { TSortDirection } from '@luxon/interfaces';


interface ITableProps<T = any> {
    columns: ITableColumn<T>[];
    rows: T[];
    idDataKey?: keyof T;
    filters?: {[key: string]: any};
    filtersChanged?: (filters: {[key: string]: any}) => void;
    sortKey?: string;
    sortDirection?: TSortDirection;
    sortingChanged?: (dataKey: string | any, direction: TSortDirection) => void;
    pageSize?: number;
    pageIndex?: number;
    totalRows?: number;
    pagingChanged?: (pageSize: number, pageIndex: number) => void;
    isLoading?: boolean;
    rowClicked?: (dataRow: T) => void;
    rowLink?: (dataRow: T) => string;
    rowLinkTarget?: HTMLAttributeAnchorTarget;
    rowLinkTitle?: string | ((dataRow: T) => string);
    noCard?: boolean;
    noPagination?: boolean;
    paginationPageSizes?: number[];
    disabled?: boolean;
    noDataTextOverride?: string;

    checkboxSelection?: boolean;
    disabledRowIds?: any[];
    selectedRowIds?: any[];
    selectedRowIdsChanged?: (rowIds: any[]) => void;

    size?: 'small' | 'medium';
}
export function Table<T>(props: ITableProps<T>) {

    const [checkboxState, setCheckboxState] = useState<'none' | 'checked' | 'indeterminate'>('none');

    const idDataKey = useMemo(() => (props.idDataKey ?? 'id') as keyof T, [props.idDataKey]);
    const visibleIds = useMemo(() => props.rows.map(x => x[idDataKey]), [idDataKey, props.rows])

    const handleFilterChanged = (dataKey: string, newFilterValue: any) => {
        if (props.disabled) {
            return;
        }

        const filtersClone = Object.assign({}, props.filters ?? {});
        filtersClone[dataKey] = newFilterValue;
        
        if (props.filtersChanged) {
            props.filtersChanged(filtersClone);
        }
    };

    const calculateSorting = (clickedDataKey: string) => {
        if (props.disabled || !props.sortingChanged || typeof props.sortingChanged !== 'function') {
            return;
        }

        const newSortDirection: TSortDirection = props.sortKey === clickedDataKey
            ? props.sortDirection === 'ASC' ? 'DESC' : 'ASC'
            : props.sortDirection;

        props.sortingChanged(clickedDataKey, newSortDirection);
    };

    const handlePageSizeChanged = (pageSize: number) => {
        if (props.pagingChanged && !props.disabled) {
            props.pagingChanged(pageSize, 0);
        }
    };
    const handlePageIndexChanged = (pageIndex: number) => {
        if (props.pagingChanged && !props.disabled) {
            props.pagingChanged(props.pageSize, pageIndex);
        }
    }

    const handleHeaderCheckboxChanged = () => {
        const selectedRowIdsClone = Object.assign([], props.selectedRowIds ?? []);
        if (checkboxState !== 'none') {
            visibleIds.forEach(x => {
                const index = selectedRowIdsClone.indexOf(x);
                if (index >= 0) {
                    selectedRowIdsClone.splice(index, 1);
                }
            });
        } else {
            selectedRowIdsClone.push(...(visibleIds.filter(x => !(props.disabledRowIds ?? []).includes(x))));
        }

        if (props.selectedRowIdsChanged) {
            props.selectedRowIdsChanged(selectedRowIdsClone);
        }
    };
    const handleRowCheckboxSelectionChanged = (row: any) => {
        const id = row[idDataKey];
        const selectedRowIdsClone = Object.assign([], props.selectedRowIds ?? []);
        const existingIndex = selectedRowIdsClone.indexOf(id);
        if (existingIndex >= 0) {
            selectedRowIdsClone.splice(existingIndex, 1);
        } else {
            selectedRowIdsClone.push(id);
        }

        if (props.selectedRowIdsChanged) {
            props.selectedRowIdsChanged(selectedRowIdsClone);
        }
    };

    const getVisibleColumns = () => props.columns.filter(x => x.visible !== false);

    useEffect(() => {
        const selectedIdsCount = visibleIds.filter(x => (props.selectedRowIds ?? []).indexOf(x) >= 0).length;
        setCheckboxState(selectedIdsCount === 0 ? 'none' : selectedIdsCount === visibleIds.length ? 'checked' : 'indeterminate');
    }, [props.selectedRowIds, props.rows, visibleIds])

    const mainTable = (
        <TableContainer component={props.noCard ? null : CardContent} style={{padding: props.noCard ? '0px' : '15px 30px 30px 30px'}}>
            <MuiTable size={props.size}>
                <TableHead>
                    <TableRow>
                        {
                            props.checkboxSelection && (
                                <TableCell sx={{width: '42px'}}>
                                    <Checkbox
                                        value={checkboxState === 'checked'}
                                        indeterminate={checkboxState === 'indeterminate'}
                                        onChange={handleHeaderCheckboxChanged}
                                        disabled={props.disabled}
                                        size={props.size}
                                    />
                                </TableCell>
                            )
                        }
                        {
                            getVisibleColumns().map(c => (
                                <TableHeaderColumn
                                    columnDef={c}
                                    filterValue={!props.filters || !props.filters[String(c.dataKey) ?? c.title] ? '' : props.filters[String(c.dataKey) ?? c.title]}
                                    filterChanged={(newVal) => handleFilterChanged(String(c.dataKey), newVal)}
                                    sortKey={props.sortKey}
                                    sortDirection={props.sortDirection}
                                    sortingClicked={() => calculateSorting(String(c.dataKey))}
                                    key={String(c.dataKey) ?? c.title}
                                    disabled={props.isLoading || props.disabled}
                                />
                            ))
                        }
                    </TableRow>
                </TableHead>
                {
                    props.isLoading ? (
                        <TableLoader columnCount={getVisibleColumns().length} />
                    ) : props.rows?.length > 0 ? (
                        <TableBody>
                            {
                                props.rows.map(row => (
                                    <TableDataRow
                                        columns={getVisibleColumns()}
                                        row={row}
                                        onSelected={() => props.rowClicked ? props.rowClicked(row) : null}
                                        disabled={props.disabled || (props.disabledRowIds ?? []).includes(row[idDataKey])}
                                        key={row[idDataKey] as any}
                                        checkboxSelection={props.checkboxSelection}
                                        checkboxChecked={(props.selectedRowIds ?? []).includes(row[idDataKey])}
                                        checkboxSelectionChanged={() => handleRowCheckboxSelectionChanged(row)}
                                        rowLink={props.rowLink}
                                        rowLinkTarget={props.rowLinkTarget}
                                        rowLinkTitle={props.rowLinkTitle}
                                        size={props.size}
                                    />
                                ))
                            }
                        </TableBody>
                    ) : (
                        <TableNoDataRow
                            columnCount={getVisibleColumns().length}
                            noDataTextOverride={props.noDataTextOverride}
                        />
                    )
                }
                {
                    !props.isLoading && props.totalRows > 0 && props.noPagination !== true && (
                        <TableFooter>
                            <TableRow>
                                {
                                    props.checkboxSelection && (
                                        <TableCell colSpan={3}>
                                            {props.selectedRowIds?.length} row{props.selectedRowIds?.length === 1 ? '' : 's'} selected
                                        </TableCell>
                                    )
                                }
                                <TablePagination
                                    rowsPerPageOptions={props.paginationPageSizes ?? [5, 10, 25, 50]}
                                    colSpan={getVisibleColumns().length - (props.checkboxSelection ? 2 : 0)}
                                    count={props.totalRows}
                                    rowsPerPage={props.pageSize ?? 10}
                                    page={props.pageIndex ?? 0}
                                    onPageChange={(e, newPageIndex) => handlePageIndexChanged(newPageIndex)}
                                    onRowsPerPageChange={(e) => handlePageSizeChanged(parseInt(e.target.value, 10))}
                                    disabled={props.disabled}
                                />
                            </TableRow>
                        </TableFooter>
                    )
                }
            </MuiTable>
        </TableContainer>
    )

    if (props.noCard) {
        return mainTable;
    }

    return (
        <Card>
            {mainTable}
        </Card>
    )
};