import { useQuery, useMutation } from "react-query";

import { IBillboardComparingMetric, IBillboardMetricDetails, IDefaultResponseMessage, IExportSingleBillboardHourlyMetricsRequest, IExportSingleBillboardMetricsRequest, IExportSingleBillboardRawMetricsRequest, IGetMetricsRequest, ILDSv1Metric, IObjectCountMetric, TMetricsRange, TObjectCountMetricType } from '@luxon/interfaces';
import { HttpClientError, getQuerySettings, IQuerySettings, QueryKeys } from '@luxon/models';
import { useHttpClient, useSnackbar } from '@luxon/hooks';

export enum MetricsSource {
  Billboard = 'billboard',
  LDSV1 = 'ldsv1'
}

export enum MetricsQueryKeys {
  BillboardMetricDetails = "BillboardMetricDetails",
  BillboardComparingMetrics = "BillboardComparingMetrics",
}

interface GetMetrics {
  source: MetricsSource;
  request: IGetMetricsRequest;
  clientIdOverride?: string;
}
export const useGetMetrics = (request: GetMetrics, settings?: IQuerySettings<IObjectCountMetric[]>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IObjectCountMetric[], HttpClientError>([QueryKeys.Metrics, request], () => {
    const urlPrefix = request.clientIdOverride ? `/v1/metrics/${request.clientIdOverride}` : `/v1/metrics`;
    return GET<IObjectCountMetric[]>(`${urlPrefix}/${request.source}`, request.request);
  }, querySettings);
}

interface GetBillboardMetricDetails {
  billboardId: string;
  clientIdOverride?: string;
}
export const useGetBillboardMetricDetails = (request: GetBillboardMetricDetails, settings?: IQuerySettings<IBillboardMetricDetails>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IBillboardMetricDetails, HttpClientError>([QueryKeys.Metrics, MetricsQueryKeys.BillboardMetricDetails, request], () => {
    const urlPrefix = request.clientIdOverride ? `/v1/metrics/${request.clientIdOverride}` : `/v1/metrics`;
    return GET<IBillboardMetricDetails>(`${urlPrefix}/billboard/${request.billboardId}`);
  }, {
    ...querySettings,
    enabled: Boolean(request.billboardId)
  });
}

interface GetBillboardComparingMetrics {
  billboardId: string;
  clientIdOverride?: string;
  objectCountMetricType: TObjectCountMetricType;
  range: TMetricsRange;
  timezoneOffset: number
}
export const useGetBillboardComparingMetrics = (request: GetBillboardComparingMetrics, settings?: IQuerySettings<IBillboardComparingMetric[]>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IBillboardComparingMetric[], HttpClientError>([QueryKeys.Metrics, MetricsQueryKeys.BillboardComparingMetrics, request], () => {
    const urlPrefix = request.clientIdOverride ? `/v1/metrics/${request.clientIdOverride}` : `/v1/metrics`;
    return GET<IBillboardComparingMetric[]>(`${urlPrefix}/billboard/${request.billboardId}/compare/${request.objectCountMetricType}`, {
      range: request.range,
      timezoneOffset: request.timezoneOffset
    });
  }, {
    ...querySettings,
    enabled: Boolean(request.billboardId)
  });
}

export const useGetLDSv1Metrics = (request: IGetMetricsRequest, settings?: IQuerySettings<ILDSv1Metric[]>) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<ILDSv1Metric[], HttpClientError>([QueryKeys.Metrics, MetricsSource.LDSV1, request], () => {
      return GET<ILDSv1Metric[]>('/v1/metrics/ldsv1', request);
  }, querySettings);
}

interface ExportSingleBillboardMetrics {
  billboardId: string;
  clientIdOverride?: string;
  request: IExportSingleBillboardMetricsRequest;
}
export const useExportSingleBillboardMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardMetrics>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardMetrics = ({ billboardId, clientIdOverride, request }: ExportSingleBillboardMetrics) => {
    const url = ['/v1/metrics', clientIdOverride, `billboard/${billboardId}/export`].filter(x => !!x).join('/');
    return GET<IDefaultResponseMessage>(url, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardMetrics>(exportSingleBillboardMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}

interface ExportSingleBillboardHourlyMetrics {
  billboardId: string;
  clientIdOverride?: string;
  request: IExportSingleBillboardHourlyMetricsRequest;
}
export const useExportSingleBillboardHourlyMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardHourlyMetrics>) => {
  const { POST } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardMetrics = ({ billboardId, clientIdOverride, request }: ExportSingleBillboardHourlyMetrics) => {
    const url = ['/v1/metrics', clientIdOverride, `billboard/${billboardId}/export-hourly`].filter(x => !!x).join('/');
    return POST<IDefaultResponseMessage>(url, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardHourlyMetrics>(exportSingleBillboardMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}

interface ExportSingleBillboardRawMetrics {
  billboardId: string;
  clientIdOverride?: string;
  request: IExportSingleBillboardRawMetricsRequest;
}
export const useExportSingleBillboardRawMetrics = (settings?: IQuerySettings<IDefaultResponseMessage, ExportSingleBillboardRawMetrics>) => {
  const { GET } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const exportSingleBillboardRawMetrics = ({ billboardId, clientIdOverride, request }: ExportSingleBillboardRawMetrics) => {
    const url = ['/v1/metrics', clientIdOverride, `billboard/${billboardId}/export-raw`].filter(x => !!x).join('/');
    return GET<IDefaultResponseMessage>(url, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ExportSingleBillboardRawMetrics>(exportSingleBillboardRawMetrics, {
    onSuccess: (response, input) => {
      showSnackbar('File download will start shortly.');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input);
      }
    },
    onError: querySettings.onError
  });
}