import React, { FunctionComponent, useEffect, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import PageLoaderPage from '@luxon/pages/page-loader/PageLoaderPage';
import { IClient, IUser, TSuspendedReason, TUserRole } from '@luxon/interfaces';
import { httpService } from '@luxon/services';
import { FeatureFlag } from '@luxon/models';
import { useAppSettings, usePageTitle, useClientContext, useUserContext } from '@luxon/hooks';

interface IProtectedRouteProps {
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser, client: IClient) => boolean;
    allowOnboardingClients?: boolean;
    allowSuspendedClients?: boolean;
    allowedSuspendedReasons?: TSuspendedReason[];
    children: any;
}
const ProtectedRoute: FunctionComponent<IProtectedRouteProps> = (props: IProtectedRouteProps) => {
    const { user, initialized: userInitialized } = useUserContext();
    const { client, initialized: clientInitialized } = useClientContext();

    if (!userInitialized || !clientInitialized) {
        return null;
    }

    const goToSignOut = (queryParams?: {[key: string]: any}) => {
        const url = httpService.buildUrl(`/sign-out/${user?.id ?? null}`, queryParams)
        return <Navigate to={url} replace />;
    }
    
    if (
        !user?.id ||
        (props.allowedRoles?.length > 0 && props.allowedRoles.indexOf(user.role) < 0) ||
        (props.isAllowed && typeof props.isAllowed === 'function' && !props.isAllowed(user, client))
    ) {
        const redirectUri = `${window.location.pathname}${window.location.search}`;
        return goToSignOut({ r: redirectUri });
    } else if (client.status === 'Onboarding' && !props.allowOnboardingClients) {
        return goToSignOut();
    } else if (client.status === 'Suspended' && !props.allowSuspendedClients && (!props.allowedSuspendedReasons || !props.allowedSuspendedReasons.find(x => x === client.suspendedReason))) {
        return goToSignOut();
    }

    return props.children;
};

interface ILazyLoaderProps extends IProtectedRouteProps {
    title?: string;
    noTitle?: boolean;

    protected?: boolean;
    allowedRoles?: TUserRole[];
    isAllowed?: (user: IUser, client: IClient) => boolean;
    allowOnboardingClients?: boolean;
    allowSuspendedClients?: boolean;
    allowedSuspendedReasons?: TSuspendedReason[];
    featureGate?: FeatureFlag;

    routeData?: any;

    children: any;
}

const LazyLoaderPageWrapper: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    const { setPageTitle } = usePageTitle();
    const { isFeatureEnabled } = useAppSettings();

    useEffect(() => {
        if (!props.noTitle) {
            setPageTitle(props.title);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title, props.noTitle]);

    if (props.featureGate && !isFeatureEnabled(props.featureGate)) {
        return <Navigate to='/' replace />
    }

    if (props.protected || props.allowedRoles?.length > 0 || (props.isAllowed && typeof props.isAllowed === 'function')) {
        return (
            <ProtectedRoute
                allowedRoles={props.allowedRoles}
                isAllowed={props.isAllowed}
                allowOnboardingClients={props.allowOnboardingClients}
                allowedSuspendedReasons={props.allowedSuspendedReasons}
                allowSuspendedClients={props.allowSuspendedClients}
            >
                {props.children}
            </ProtectedRoute>
        )
    }

    return props.children;
};

const LazyLoader: FunctionComponent<ILazyLoaderProps> = (props: ILazyLoaderProps) => {

    return (
        <Suspense fallback={<PageLoaderPage />}>
            <LazyLoaderPageWrapper {...props} />
        </Suspense>
    )
};

export default LazyLoader;