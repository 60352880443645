import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './styles/Chart.scss';

import { Box, Stack, Typography, Tooltip as MuiTooltip } from '@mui/material';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

import { TChartLegend, TChartType, TColor } from '@luxon/interfaces';
import { formatNumber } from '@luxon/formatters';
import { createResizeObserver } from '@luxon/utils';

import lineChartNoDataBackground from '@luxon/images/line-chart-no-data-background.webp';
import areaChartNoDataBackground from '@luxon/images/area-chart-no-data-background.webp';
import barChartNoDataBackground from '@luxon/images/bar-chart-no-data-background.webp';
import pieChartNoDataBackground from '@luxon/images/pie-chart-no-data-background.webp';
import radarChartNoDataBackground from '@luxon/images/radar-chart-no-data-background.webp';

import LineChart from './LineChart';
import AreaChart from './AreaChart';
import BarChart from './BarChart';
import PieChart from './PieChart';
import RadarChart from './RadarChart';

export interface IReferenceLine {
    x?: any;
    y?: any;
    color?: TColor;
    label?: string;
}

export interface IGenericChartProps {
    data: any[];
    dataKeys: IChartDataKey[];
    width: number;
    height: number;
    disabledKeys: string[];
    children: React.ReactNode;
    chartColor: TColor;
}

export interface IChartDataKey {
    dataKey: string;
    legendName: TChartLegend | string;
    color: TColor;
    unit?: string;
    stackKey?: string;
}

interface IChartProps {
    data: any[];
    type: TChartType;
    heightRatio?: number;
    fullHeight?: boolean;
    dataKeys: IChartDataKey[];
    xAxisDataKey?: string;
    xAxisLabel?: (data: any) => string;
    yAxisDataKey?: string;
    referenceLines?: IReferenceLine[];
    hideGrid?: boolean;
    hideLegends?: boolean;
    chartColor?: TColor;
    legendsChanged?: (legends: TChartLegend[]) => void;
}
export const Chart: FunctionComponent<IChartProps> = (props: IChartProps) => {

    const [chartHeight, setChartHeight] = useState(0);
    const [chartWidth, setChartWidth] = useState(0);
    const [disabledKeys, setDisabledKeys] = useState<string[]>([]);
    const lineChartWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!lineChartWrapperRef.current) {
            return;
        }
        const resizeObserver = createResizeObserver(() => {
            if (!lineChartWrapperRef.current) {
                return;
            }

            setChartWidth(lineChartWrapperRef.current.clientWidth);
            if (props.fullHeight) {
                setChartHeight(lineChartWrapperRef.current.clientHeight);
            } else {
                setChartHeight(lineChartWrapperRef.current.clientWidth * (props.heightRatio ?? 0.6));
            }
        });
        resizeObserver.observe(lineChartWrapperRef.current);
        
        return () => {
            resizeObserver.disconnect();
        }
    }, [lineChartWrapperRef, props.heightRatio, props.fullHeight]);

    const getNoDataBackground = (): string => {
        switch(props.type) {
            case 'area': return areaChartNoDataBackground;
            case 'bar': return barChartNoDataBackground;
            case 'pie': return pieChartNoDataBackground;
            case 'radar': return radarChartNoDataBackground;
            default: return lineChartNoDataBackground;
        }
    }

    const toggleLegend = (dataKey: string) => {
        let disabledKeysClone = disabledKeys.splice(0);
        const index = disabledKeysClone.indexOf(dataKey);
        if (index >= 0) {
            disabledKeysClone.splice(index, 1);
        } else {
            disabledKeysClone.push(dataKey);
        }
        if (disabledKeysClone.length === props.dataKeys.length || (disabledKeysClone.length === props.dataKeys.length - 1 && props.type === 'pie') || (disabledKeysClone.length === props.dataKeys.length - 2 && props.type === 'radar')) {
            disabledKeysClone = [];
        }
        setDisabledKeys(disabledKeysClone);
        if (props.legendsChanged) {
            props.legendsChanged(props.dataKeys.filter(x => disabledKeysClone.indexOf(x.dataKey) < 0).map(x => x.legendName as TChartLegend));
        }
    }

    const chartTooltip = (data: any) => {
        if (!data.active || !data.payload || data.payload.length <= 0) {
            return null;
        }

        const valueLabels: Array<{ text: string, key: string, color: string }> = data.payload.map((payload: any) => {
            const dataKey = props.type === 'pie' || props.type === 'radar' ? payload.payload.key : payload.dataKey;
            const dataName = props.type === 'pie' || props.type === 'radar' ? payload.payload.label : payload.name;
            let dataValue = props.type === 'pie' || props.type === 'radar' ? payload.payload.value : payload.payload[dataKey];
            if (typeof dataValue === 'number') {
                dataValue = formatNumber(dataValue);
            }

            const targetDataKey = props.dataKeys.find(x => x.dataKey === dataKey);
            const label = [
                dataName,
                ' : ',
                dataValue,
                targetDataKey?.unit ?? ''
            ].join('');
            return {
                text: label,
                key: dataKey,
                color: targetDataKey.color
            };
        });

        return (
            <Stack className='chart-tooltip' spacing={1}>
                <Typography variant='caption'>{data.label}</Typography>
                {
                    valueLabels.map(label => (
                        <Typography key={label.key} color={label.color} variant='body2'>{label.text}</Typography>
                    ))
                }
            </Stack>
        )
    };

    const chartLegend = (data: any): JSX.Element => {
        const { payload }: { payload: Array<{ dataKey: string, color: TColor, value: string }> } = data;

        return (
            <Stack
                display='flex'
                flexDirection='row'
                columnGap={2}
                justifyContent='flex-start'
                alignItems='center'
                className='chart-legends'
            >
                {
                    payload.map(item => (
                        <MuiTooltip
                            key={item.dataKey}
                            title='Click to toggle'
                        >
                            <Stack
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                className={`legend-label ${disabledKeys.indexOf(item.dataKey) < 0 ? 'active' : 'inactive'}`}
                                onClick={() => toggleLegend(item.dataKey)}
                            >
                                <Box
                                    className='legend-dot'
                                    sx={{
                                        backgroundColor: `var(--color-${item.color})`,
                                        borderColor: `var(--color-${item.color})`,
                                        textDecoration: 'inherit'
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: `var(--color-${item.color})`,
                                        textDecoration: 'inherit'
                                    }}
                                >
                                    {item.value}
                                </Typography>
                            </Stack>
                        </MuiTooltip>
                    ))
                }
            </Stack>
        )
    }

    const chartComponents = (
        <>
            {
                props.type !== 'pie' && props.type !== 'radar' && (
                    <>
                        {
                            !props.hideGrid && (
                                <CartesianGrid strokeDasharray="3 3" />
                            )
                        }
                        <XAxis dataKey={props.xAxisDataKey ?? props.xAxisLabel} />
                        <YAxis
                            domain={[(dataMin: number) => Math.floor(dataMin - (dataMin * 0.05)), (dataMax: number) => dataMax < 1 && dataMax > -1 ? (Math.ceil(dataMax * 1000) / 1000) : Math.ceil(dataMax)]}
                            type='number'
                        />
                    </>
                )   
            }

            <Tooltip
                content={chartTooltip}
                isAnimationActive={false}
            />

            {
                !props.hideLegends && (
                    <Legend
                        formatter={(value, entry, index) => {
                            return props.type === 'pie' ? (entry.payload as any).label : entry.value;
                        }}
                        payload={props.dataKeys.map(x => ({
                            dataKey: x.dataKey,
                            color: x.color,
                            value: x.legendName
                        }))}
                        content={chartLegend}
                    />
                )
            }

            {
                props.referenceLines && props.referenceLines.length > 0 && props.referenceLines.map((line, index) => (
                    <ReferenceLine
                        key={index}
                        stroke={`var(--color-${line.color})`}
                        strokeWidth={1}
                        label={line.label}
                        x={line.x}
                        y={line.y}
                    />
                ))
            }
        </>
    );

    const getChart = () => {
        const chartProps: IGenericChartProps = {
            data: props.data,
            dataKeys: props.dataKeys,
            height: chartHeight,
            width: chartWidth,
            children: chartComponents,
            disabledKeys: disabledKeys,
            chartColor: props.chartColor
        };
        switch(props.type) {
            case 'line': return <LineChart {...chartProps} />;
            case 'area': return <AreaChart {...chartProps} />;
            case 'bar': return <BarChart {...chartProps} />;
            case 'pie': return <PieChart {...chartProps} />;
            case 'radar': return <RadarChart {...chartProps} />
        }
    }

    return (
        <div ref={lineChartWrapperRef} className='chart-wrapper'>
            {
                props.data && props.data.length === 0 ? (
                    <Box
                        sx={{height: chartHeight, width: chartWidth}}
                        className='chart-no-data-wrapper'
                    >
                        <img
                            src={getNoDataBackground()}
                            alt='No data available'
                            className='absolute-fill' />
                        <Typography sx={{zIndex: '1'}} variant='h5' textAlign='center'>No data available</Typography>
                    </Box>
                ) : props.data?.length > 0 ? getChart() : null
            }
        </div>
    )
};