import { useQuery, useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';

import { IGetUsersRequest, IUser, IPaginationWrapper, IDefaultResponseMessage, IAuthenticateUserResponse, IAuthenticateUserRequest, IResetPasswordRequest } from '@luxon/interfaces';
import { HttpClientError, QueryKeys, getQuerySettings, IQuerySettings } from '@luxon/models';
import { useHttpClient, useSnackbar, useClientContext, useUserContext } from '@luxon/hooks';

export enum UserQueryKeys {
  Self = 'Self'
}

/**
 * Gets the current user
 * 
 * ## Responses
 * - `200` - _User_
 * - `400` - _Invalid Data_
 * - `404` - _User does not exist_
 * - `500` - _Unexpected Error_
 */
export const useGetSelf = (settings?: IQuerySettings) => {
  const { GET } = useHttpClient();

  const querySettings = getQuerySettings(settings);
  return useQuery<IUser, HttpClientError>([QueryKeys.Users, UserQueryKeys.Self], () => {
      return GET<IUser>(`/v1/users/self`, null, {
        autoShowErrorMessages: querySettings.autoShowErrorMessages,
        autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
      });
  }, {
    enabled: querySettings.enabled,
    onSuccess: querySettings.onSuccess,
    onError: querySettings.onError,
    staleTime: querySettings.staleTime
  });
}

/**
 * Gets users in an organization
 * @param clientId Unique Identifier of the Client
 * @param request Search parameters
 * 
 * ## Responses
 * - `200` - _Users_
 * - `400` - _Invalid Data_
 * - `500` - _Unexpected Error_
 */
export const useGetUsers = (clientId: string, request: IGetUsersRequest, settings?: IQuerySettings) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IPaginationWrapper<IUser>, HttpClientError>([QueryKeys.Users, clientId, request], () => {
        return GET<IPaginationWrapper<IUser>>(`/v1/clients/${clientId}/users`, request);
    }, {
      enabled: querySettings.enabled && !!clientId,
      onSuccess: querySettings.onSuccess,
      onError: querySettings.onError,
      staleTime: querySettings.staleTime,
    });
}

/**
 * Sends a password reset link
 * @param email The email to send to
 * 
 * ## Responses
 * - `200` - _Email sent_
 * - `500` - _Unexpected Error_
 */
export const useSendPasswordResetEmail = (settings?: IQuerySettings<IDefaultResponseMessage, { email: string }>) => {
  const { POST, buildUrl } = useHttpClient();
  const { showSnackbar } = useSnackbar();

  const sendPasswordResetEmail = ({ email }: { email: string }) => {
    return POST<IDefaultResponseMessage>(buildUrl('/v1/users/send-password-reset', { email }));
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, { email: string }>(sendPasswordResetEmail, {
    onSuccess: (response, input) => {
      showSnackbar('Password reset link sent');

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input)
      }
    }, onError: (err, input) => {
      if (querySettings.onError) {
        querySettings.onError(err, input);
      }
    }
  });
}


interface ResetUserPasswordRequest {
  clientId: string;
  userId: string;
  request: IResetPasswordRequest;
}
/**
 * Resets a users password
 * @param clientId Unique Identifier of the Client
 * @param userId Unique Identifier of the user
 * @param request The password reset request
 * 
 * ## Responses
 * - `200` - _Password Reset Success_
 * - `400` - _Invalid Data_
 * - `404` - _User does not exist or is inactive_
 * - `500` - _Unexpected Error_
 */
export const useResetUserPassword = (settings?: IQuerySettings<IDefaultResponseMessage, ResetUserPasswordRequest>) => {
  const { POST } = useHttpClient();

  const resetUserPassword = ({ clientId, userId, request }: ResetUserPasswordRequest) => {
    return POST<IDefaultResponseMessage>(`/v1/clients/${clientId}/users/${userId}/password-reset`, request);
  }

  const querySettings = getQuerySettings(settings);
  return useMutation<IDefaultResponseMessage, HttpClientError, ResetUserPasswordRequest>(resetUserPassword, {
    ...querySettings
  });
}

/**
 * Authenticates a user
 * @param request Authentication parameters
 * 
 * ## Responses
 * - `200` - _User authenticated_
 * - `400` - _Invalid Data_
 * - `404` - _User does not exist or password is invalid_
 * - `500` - _Unexpected Error_
 */
export const useAuthenticateUser = (settings?: IQuerySettings<IAuthenticateUserResponse, IAuthenticateUserRequest>) => {
  const { POST, buildQueryParamString } = useHttpClient();
  const { showSnackbar, showError } = useSnackbar();
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const { setClient } = useClientContext();
  
  const querySettings = getQuerySettings(settings);
  const authenticateUser = (request: IAuthenticateUserRequest) => {
    return POST<IAuthenticateUserResponse>(`/v1/users/auth`, request, null, {
      autoShowErrorMessages: querySettings.autoShowErrorMessages
    });
  }

  return useMutation<IAuthenticateUserResponse, HttpClientError, IAuthenticateUserRequest>(authenticateUser, {
    onSuccess: (response, input) => {
      if (response.authChallenge === 'EnableTwoFactor') {
        const queryParamString = buildQueryParamString({
          t: btoa(response.authChallengeToken),
          te: btoa(response.authChallengeExtraDetails),
          id: response.user.id,
          cid: response.client.id,
          rm: input.rememberMe ?? false
        });
        navigate(`/2fa/setup${queryParamString}`);
      } else if (response.authChallenge === 'VerifyTwoFactor') {
        const queryParamString = buildQueryParamString({
          id: response.user.id,
          cid: response.client.id,
          rm: input.rememberMe ?? false
        });
        navigate(`/2fa/verify${queryParamString}`);
      } else {
        showSnackbar(`Welcome ${response.user.name}`);
        setUser(response.user);
        setClient(response.client);
      }

      if (querySettings.onSuccess) {
        querySettings.onSuccess(response, input)
      }
    },
    onError: (error, input) => {
      if (error.statusCode === 400 && error.errorResponse.rawErrors?.ErrorType && error.errorResponse.rawErrors.ErrorType[0] === 'SuspendedAccount') {
        showError('Your account is not active. Please contact Luxon for assistance.');
      } else {
        showError(error.firstErrorMessage);
      }

      if (querySettings.onError) {
        querySettings.onError(error, input);
      }
    }
  });
}