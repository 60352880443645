import React, { FunctionComponent, useReducer, useEffect, useState } from 'react';

import { IMetricsRangeForm, IReducerAction } from '@luxon/interfaces';
import { DATE_FORMATS } from '@luxon/constants';
import dayjs from 'dayjs';

type TLocalStorageActions = 'SET_LOCAL_STORE' | 'SET_NAV_EXPANDED' | 'SET_METRICS_RANGE' | 'RESET_METRICS_RANGE';

interface ILocalStorageContext {
    navExpanded: boolean;
    setNavExpanded?: (val: boolean) => void;
    metricsRange: IMetricsRangeForm;
    setMetricsRange?: (val: IMetricsRangeForm) => void;
    resetMetricsRange?: () => void;
}

const initialState: ILocalStorageContext = {
    navExpanded: false,
    metricsRange: {
        range: 'PastDay',
        startDateTime: dayjs().add(-1, 'day').startOf('day'),
        endDateTime: dayjs().endOf('day'),
        dateFormat: DATE_FORMATS.DayMonthHour
    }
};
export const LocalStorageContext = React.createContext<ILocalStorageContext>(initialState);

const LocalStorageContextReducer = (state: ILocalStorageContext, action: IReducerAction<TLocalStorageActions>): ILocalStorageContext => {
    switch (action.type) {
        case 'SET_LOCAL_STORE':
            return {...state, ...action.payload};
        case 'SET_NAV_EXPANDED':
            return {...state, navExpanded: action.payload };
        case 'SET_METRICS_RANGE':
            return {...state, metricsRange: action.payload };
        case 'RESET_METRICS_RANGE':
            return {...state, metricsRange: {...(initialState.metricsRange)} };
        default:
            return state;
    }
};

const providerActions = {
    setNavExpanded: (dispatcher: React.Dispatch<IReducerAction<TLocalStorageActions>>, val: boolean) => {
        dispatcher({ type: 'SET_NAV_EXPANDED', payload: val });
    },
    setMetricsRange: (dispatcher: React.Dispatch<IReducerAction<TLocalStorageActions>>, val: IMetricsRangeForm) => {
        dispatcher({ type: 'SET_METRICS_RANGE', payload: val });
    },
    resetMetricsRange: (dispatcher: React.Dispatch<IReducerAction<TLocalStorageActions>>) => {
        dispatcher({ type: 'RESET_METRICS_RANGE' });
    }
};

interface ILocalStorageProviderProps {
    children: any;
};
const LOCAL_STORAGE_KEY = 'luxon_local_s';
export const LocalStorageContextProvider: FunctionComponent<ILocalStorageProviderProps> = (props: ILocalStorageProviderProps) => {
    const [state, dispatch] = useReducer(LocalStorageContextReducer, {...initialState,
        setNavExpanded: (val: boolean) => providerActions.setNavExpanded(dispatch, val),
        setMetricsRange: (val: IMetricsRangeForm) => providerActions.setMetricsRange(dispatch, val),
        resetMetricsRange: () => providerActions.resetMetricsRange(dispatch)
    });
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        let localStoreRaw = localStorage.getItem(LOCAL_STORAGE_KEY); 
        if (!localStoreRaw) {
            localStoreRaw = '{}';
        }

        let localStore: ILocalStorageContext = {...initialState};
        try {
            localStore = JSON.parse(localStoreRaw, (key: string, val: any) => {
                if (typeof val === 'string' && val.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{1,}Z$/)) {
                    return dayjs(val);
                }
                return val;
            });
        } catch {}

        dispatch({
            type: 'SET_LOCAL_STORE',
            payload: localStore
        });
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (!initialized) {
            return;
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <LocalStorageContext.Provider value={state}>
            {props.children}
        </LocalStorageContext.Provider>
    );
}