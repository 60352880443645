import { FunctionComponent } from "react";
import { Skeleton, Stack } from "@mui/material";

export const PageFrameSkeletonLoader: FunctionComponent = () => {
  return (
      <Stack spacing={3}>
          <Skeleton style={{height: '30px', width: '70%'}} />
          <Skeleton style={{height: '30px', width: '40%'}} />
          <Skeleton style={{height: '30px', width: '90%'}} />
      </Stack>
  )
};