import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

type SetSearchParams<T> = Partial<T> | ((currentParams: T) => T);

type RouteData<T> = T & {
  setSearchParams:  (params: SetSearchParams<T>, replace?: boolean) => void;
  routeParamData: Partial<Record<string, string>>;
  queryParamData: Record<string, string>;
  initialized: boolean;
}

export function useRouteData<T>(): RouteData<T> {
  const [routeData, setRouteData] = useState<T | null>(null);

  const [routeParamData, setRouteParamData] = useState<Partial<Record<string, string>> | null>(null);
  const [queryParamData, setQueryParamData] = useState<Record<string, string> | null>(null);

  const params = useParams<Record<string, string>>();
  const [searchParams, setSearchParamsInternal] = useSearchParams();

  const setSearchParams = (params: SetSearchParams<T>, replace?: boolean): void => {
    if (queryParamData) {
      Object.keys(queryParamData).forEach(key => {
        if (searchParams.has(key)) {
          searchParams.delete(key);
        }
      });
    }

    if (typeof params === 'function') {
      const updatedQueryParams = params(queryParamData as T);
      Object.keys(updatedQueryParams as any).forEach((key) => {
        const value = ((updatedQueryParams as any)[key] ?? '');
        if (value || value === false || value === 0) {
          searchParams.set(key, value);
        }
      });
    } else {
      Object.keys(params).forEach((key) => {
        const value = ((params as any)[key] ?? '');
        if (value || value === false || value === 0) {
          searchParams.set(key, value);
        }
      });
    }

    setSearchParamsInternal(searchParams, {
      replace
    });
  }

  useEffect(() => {
    setRouteParamData(params ?? null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!searchParams) {
      return;
    }

    const newSearchParams: Record<string, string> = {};
    Array.from(searchParams.keys()).forEach(key => {
      newSearchParams[key] = searchParams.get(key) ?? ''
    });

    setQueryParamData(newSearchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!routeParamData || !queryParamData) {
      return;
    }

    setRouteData({
      ...routeParamData,
      ...queryParamData
    } as T);
  }, [routeParamData, queryParamData]);

  return {
    ...((routeData ?? {}) as T),
    setSearchParams,
    queryParamData: queryParamData ?? {},
    routeParamData: routeParamData ?? {},
    initialized: !!routeData
  };
}